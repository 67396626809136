import React from 'react';
import firebase from '../../firebase';

export const getHistory = (id) => async (dispatch) => {
    try {
        console.log(id);
        const timesheetRef = await firebase.firestore().collection("timesheet").where("userId","==", id).get();
        const timesheet = timesheetRef.docs.map(item => ({...item.data()}));
        dispatch({ type: "GET_HISTORY", payload: timesheet });
        
    } catch (error) {
        console.error(error);
    }
};
