import firebase from "../../firebase";
import axios from "axios";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var functions = RepositoryFactory.get("functions");
async function checkEINExists(ein) {
  const querySnapshot = await firebase
    .firestore()
    .collection("charities")
    .where("ein", "==", ein)
    .get();
  if (querySnapshot.empty) {
    return false;
  }
  return true;
}

export const register = (creds) => {
  return async (dispatch) => {
    console.log("in action", creds);
    const charityVerification = JSON.parse(
      localStorage.getItem("verification_detail")
    );
    if (charityVerification) {
      const einExists = await checkEINExists(charityVerification.ein);
      if (einExists) {
        alert("Sorry EIN already exist!");
        localStorage.removeItem("verification_detail");
      } else {
        dispatch({
          type: "SIGNUP_REQUEST",
        });
        firebase
          .auth()
          .createUserWithEmailAndPassword(creds.email, creds.password)
          .then(async (data) => {
            let uid = data.user.uid;
            // let storageRef = firebase.storage().ref('user_img');
            // let { image } = creds;
            // if (image) {
            //   let parts = image.name.split('.');
            //   let ext = parts[parts.length - 1];
            //   let filename = uid + '.' + ext;
            //   await storageRef.child(filename).put(image);
            //   creds['image'] = await storageRef.child(filename).getDownloadURL();
            // }
            delete creds["password"];
            firebase
              .firestore()
              .collection("company")
              .doc(uid)
              .set({ ...creds, isCharity: true, ein: charityVerification.ein })
              .then(async (query) => {
                const companyId = uid;
                const charityRef = await firebase
                  .firestore()
                  .collection("charities")
                  .add({
                    companyId: companyId,
                    ein: charityVerification.ein,
                  });
                const charityId = charityRef.id;
                await firebase.firestore().collection("company_pricings").add({
                  companyId: companyId,
                  charityId: charityId,
                  pricing: 0,
                });
                localStorage.removeItem("verification_detail");
                dispatch({
                  type: "REGISTER_SUCCESS",
                });

                let obj = {
                  uid: uid,
                  email: creds.email,
                };
                let payment = {
                  employee_details: [],
                  no_of_employees: 0,
                };

                await firebase
                  .firestore()
                  .collection("unpaid_payments")
                  .doc(uid)
                  .set({ ...payment })
                  .then((res) => {
                    console.log("added");
                    alert("Company Register Successfully");
                  })
                  .catch((err) => console.log("error", err));
                console.log("object", obj);
                // let detail = { uid: uid, email: creds.email };
                // const { data } = await functions.sendMail(detail);
                // if (data.success) {
                //   alert(
                //     "Account Created Successfully,Please Check Your Email For Verification Link"
                //   );
                // }
                // await axios.post(
                //   "https://us-central1-work-chat-67aa9.cloudfunctions.net/sendVerificationLinkMail",
                //   { uid: uid, email: creds.email }
                // );
                // alert(
                //   "Account Created Successfully,Please Check Your Email For Verification Link"
                // );
              })
              .catch((error) => {
                dispatch({
                  type: "REGISTER_FAIL",
                });

                console.log("error", error);
                alert(error);
              });
          })
          // console.log('Error')
          .catch((error) => {
            dispatch({
              type: "SIGNUP_REQUEST_END",
            });
            alert(error);
          });
      }
    } else {
      dispatch({
        type: "SIGNUP_REQUEST",
      });
      firebase
        .auth()
        .createUserWithEmailAndPassword(creds.email, creds.password)
        .then(async (data) => {
          let uid = data.user.uid;
          // let storageRef = firebase.storage().ref('user_img');
          // let { image } = creds;
          // if (image) {
          //   let parts = image.name.split('.');
          //   let ext = parts[parts.length - 1];
          //   let filename = uid + '.' + ext;
          //   await storageRef.child(filename).put(image);
          //   creds['image'] = await storageRef.child(filename).getDownloadURL();
          // }
          delete creds["password"];
          firebase
            .firestore()
            .collection("company")
            .doc(uid)
            .set({ ...creds })
            .then(async (query) => {
              dispatch({
                type: "REGISTER_SUCCESS",
              });

              let obj = {
                uid: uid,
                email: creds.email,
              };
              let payment = {
                employee_details: [],
                no_of_employees: 0,
              };
              firebase
                .firestore()
                .collection("unpaid_payments")
                .doc(uid)
                .set({ ...payment })
                .then((res) => {
                  console.log("added");
                  alert("Company Register Successfully");
                })
                .catch((err) => console.log("error", err));
              console.log("object", obj);
              // let detail = { uid: uid, email: creds.email };
              // const { data } = await functions.sendMail(detail);
              // if (data.success) {
              //   alert(
              //     "Account Created Successfully,Please Check Your Email For Verification Link"
              //   );
              // }
              // await axios.post(
              //   "https://us-central1-work-chat-67aa9.cloudfunctions.net/sendVerificationLinkMail",
              //   { uid: uid, email: creds.email }
              // );
              // alert(
              //   "Account Created Successfully,Please Check Your Email For Verification Link"
              // );
            })
            .catch((error) => {
              dispatch({
                type: "REGISTER_FAIL",
              });

              console.log("error", error);
              alert(error);
            });
        })
        // console.log('Error')
        .catch((error) => {
          dispatch({
            type: "SIGNUP_REQUEST_END",
          });
          alert(error);
        });
    }
  };
};

export const registerComplete = () => {
  return (dispatch) => {
    dispatch({
      type: "REGISTER_COMPLETE",
    });
  };
};

//Login...

export const login = (creds) => {
  return (dispatch) => {
    console.log(creds);
    dispatch({
      type: "ACTION_REQUEST",
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then(async (data) => {
        firebase
          .firestore()
          .collection("company")
          .doc(data.user.uid)
          .onSnapshot((doc) => {
            console.log(doc.data());
            var tempUser = {};
            tempUser = { id: doc.id, ...doc.data() };

            dispatch({
              type: "LOGIN_SUCCESS",
              user: tempUser,
              error: "",
            });
            dispatch({
              type: "ACTION_REQUEST_END",
            });
          });
        // if (!data.user.emailVerified) {
        //   alert("Please Verify Your Email First");
        //   dispatch({
        //     type: "ACTION_REQUEST_END",
        //   });
        // } else {
        //   firebase
        //     .firestore()
        //     .collection("company")
        //     .doc(data.user.uid)
        //     .onSnapshot((doc) => {
        //       console.log(doc.data());
        //       var tempUser = {};
        //       tempUser = { id: doc.id, ...doc.data() };

        //       dispatch({
        //         type: "LOGIN_SUCCESS",
        //         user: tempUser,
        //         error: "",
        //       });
        //       dispatch({
        //         type: "ACTION_REQUEST_END",
        //       });
        //     });
        // }
      })
      .catch((error) => {
        dispatch({
          type: "LOGIN_FAIL",
          uid: "",
          error: error,
        });
        dispatch({
          type: "ACTION_REQUEST_END",
        });
        alert(error, "danger");
      });
  };
};

//Logout...

export const logout = () => {
  return (dispatch) => {
    console.log("Logging Out");
    firebase
      .auth()
      .signOut()
      .then((data) => {
        dispatch({
          type: "LOGOUT_SUCCESS",
          uid: "",
          error: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: "LOGOUT_FAIL",
          uid: "",
          error: error,
        });
      });
  };
};

//Logout...
const emailRejex = /^\S+@\S+\.\S+$/;
export const sendCode = (email) => {
  return (dispatch) => {
    console.log(email, 'aaaaaaaaaaaaaaaaaaaaaaa');
    if(!emailRejex.test(email)){
      alert('Email is Not valid', "error");
      return;
    }
    firebase
      .auth()
      .sendPasswordResetEmail(email, {handleCodeInApp: true, url: `${window.location.origin}/auth/login`})
      .then((data) => {
        alert('please check email', "success");
      })
      .catch((error) => {
        alert(error, "error");
      });
  };
};

export const forgetPassword = (code, password) => {
  return (dispatch) => {
    firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then((data) => {
        alert('Password Reset Successfull', "success");
      })
      .catch((error) => {
        alert(error, "error");
      });
  };
};

// update Profile...

// email password in db...
export const updateData = (id, credentials) => {
  console.log("my creds", credentials);
  return (dispatch) => {
    // firebase
    //   .firestore()
    //   .collection("company")
    //   .doc(id)
    //   .update({
    //     name: credentials.name,
    //     email: credentials.email,
    //   })
    //   .then((res) => {
    //     let user = JSON.parse(localStorage.getItem("user"));
    //     console.log("user", user);
    //     let obj;
    //     obj.user.name = credentials.name;
    //     obj.user.email = credentials.email;
    //     localStorage.setItem("user", JSON.stringify(obj));
    //     dispatch({
    //       type: "UPDATE_DATA",
    //     });
    //   })
    //   .catch((err) => console.log("error", err));

    //email..password..

    var user = firebase.auth().currentUser;
    if (user != null) {
      var cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        credentials.Currentpassword
      );
      user
        .reauthenticateWithCredential(cred)
        .then((res) => {
          if (credentials.password == credentials.Confirmpassword) {
            user
              .updatePassword(credentials.password)
              .then((res) => {
                console.log("update password");
                alert("Password Updated Successfully!");
              })
              .catch((err) => {
                console.log("error in password", err);
                // alert("error in password...");
              });
          } else {
            alert("Password and Confirm Password not Matched!");
          }
        })
        .catch((err) => {
          alert(err);
        });
    }

    // user
    //   .updateEmail(credentials.email)
    //   .then((res) => {
    //     console.log("update email response", res);
    //   })
    //   .catch((err) => {
    //     console.log("error in main email", err);
    //   });
  };
};

export const userVerified = (id) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "https://us-central1-work-chat-67aa9.cloudfunctions.net/verifyUserEmail",
        { uid: id }
      );

      if (data.success) {
        dispatch({ type: "VERIFIED_ACCOUNT" });
      } else {
        console.log(data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createRefcode = (id) => {
  return async (dispatch) => {
    try {
      const code = makeid(10);
      console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',code);
      await firebase.firestore()
      .collection("company")
      .doc(id).update({'ref': code});
      dispatch({
        type: 'SET_REF',
        ref: code
      })
    } catch (error) {
      console.log(error);
    }
  };
};


function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
