import React from 'react'
import Lottie from "react-lottie";

import animation1 from '../../assets/img/lotties/virtual-job.json'
import animation2 from '../../assets/img/lotties/work-from-home.json'
import animation3 from '../../assets/img/lotties/workspace.json'
import animation4 from '../../assets/img/lotties/business-chat.json'


const FreePricingSection = () => {
    const animation = [
        animation1,
        animation2,
        animation3,
        animation4
    ]
    return (
        <div className="position-relative bg-white">
            <div className="py-5 home-side-padding">
                {/* <h1 className="font-family-2 text-center" style={{ fontWeight: '700' }}>FREE</h1>
                <p className='font-family-2 text-center mt-3 px-4' style={{ lineHeight: '190%' }}>
                    Businesses can join for free and sign up all of their employees.<br />
                    Businesses then have a choice to upgrade their account with paid features.
                </p> */}
                <div className='align-items-center free-pricng-animation pt-3'>
                    {
                        animation.map((e, key) => {
                            return (
                                <Lottie
                                    key={key}
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: e,
                                    }}
                                    style={{ margin: 0, flex: 1 }}
                                />
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default FreePricingSection