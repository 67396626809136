// chrome.exe --user-data-dir="C://Chrome dev session" --disable-web-security
import axios from "axios";
const baseDomain = "https://api.charityapi.org/api/public_charity_check/";
const baseURL = `${baseDomain}`;
const live_api_key = "live-ZzBde2mdizwdMstIB6VJ_bYSFon8iCyB6dCy-nlppA_E7248nX3iMt8hxp6gyFIn6RA_MhiAqFSXx83E"
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json',
  apiKey: live_api_key },
});
export default axiosObj;
