import About from "../../components/landing/About";
import Features from "../../components/landing/Features";
import Pricing from "../../components/landing/Pricing";
import Contact from "../../components/landing/Contact";

import Headerlanding from "../../components/global/Headerlanding";
import Footer from "../../components/global/Footer";
import { Button } from "reactstrap";
import Lottie from "react-lottie";
import Benefits from "../../components/landing/Benefits";
import BlogSection from "../../components/landing/Blog";
import Industries from "../../components/landing/Industries";
import Charity from "../../components/landing/Charity";
import RequestDemo from "../../components/landing/RequestDemo";
import FreePricingSection from "./FreePricing";
import { Col, Container, Row } from "reactstrap";

import F1 from "../../assets/img/Landing page-slicing/message-call.png";
import F2 from "../../assets/img/Landing page-slicing/audio-call.png";
import F3 from "../../assets/img/Landing page-slicing/video-call.png";
import F4 from "../../assets/img/Landing page-slicing/push-talk.png";

const Landing = (props) => {
  return (
    <>
      <div id="top" className="element">
        <Headerlanding />
      </div>

      {/* <div id="feature" className="element">
        <Features />
      </div> */}
      <div id="about" className="element">
        <About />
      </div>
      <div id="features" className="element">
        <div className="bg-white pt-4 pb-4">
          <div className="bg-white about2 pt-5">
            <h1
              className="font-family-2 text-center py-4"
              style={{ fontWeight: "700" }}
            >
              Communication Features
            </h1>
            <Row md="4" sm="1" xs="1" className="img-div pt-4 pb-5 mb-0">
              <Col className="px-4">
                <img src={F1} />
                <h4 className="text-center pt-3" style={{ fontWeight: "700" }}>
                  Message
                </h4>
              </Col>
              <Col className="px-4">
                <img src={F2} />
                <h4 className="text-center pt-3" style={{ fontWeight: "700" }}>
                  Audio Call
                </h4>
              </Col>
              <Col className="px-4">
                <img src={F3} />
                <h4 className="text-center pt-3" style={{ fontWeight: "700" }}>
                  Video Call
                </h4>
              </Col>
              <Col className="px-4">
                <img src={F4} />
                <h4 className="text-center pt-3" style={{ fontWeight: "700" }}>
                  Push to Talk
                </h4>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div id="benefits" className="element">
        <Benefits />
      </div>
      {/* <div id="industries" className="element">
        <Industries />
      </div> */}
      {/* <div id="pricing-free" className="element">
        <FreePricingSection />
      </div> */}
      {/* <div id="pricing" className="element">
        <Pricing />
      </div> */}
      {/* <div id="blog" className="element">
        <BlogSection />
      </div> */}
      <div id="contact" className="element">
        <Charity />
      </div>
      {/* <RequestDemo /> */}
      <Footer />
      <Button
        color="site-primary"
        href="/auth/register"
        className="position-fixed d-flex align-content-center justify-content-center"
        style={{
          bottom: "2rem",
          left: "2rem",
          height: "48px",
          width: "90px",
          borderRadius: "1rem",
          fontWeight: "700",
        }}
        type="button"
      >
        Sign Up
      </Button>
    </>
  );
};

export default Landing;
