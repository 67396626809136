import Footer from "../../components/global/Footer";
import Navbar from "../../components/global/Navbar";
import { Link } from "react-router-dom";
import moment from "moment";
import { fetchBlogs } from "../../Redux/Actions/blogsAction";
import { useDispatch, useSelector } from "react-redux";
import LoadingComp from "../../components/global/LoadingComp";

import {
  Col,
  Container,
  Row,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from "reactstrap";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import blogImage from '../../assets/img/Landing page-slicing/blog-image.png'


const BlogList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { blogs, isGettingBlogs } = useSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(fetchBlogs());
    window.scrollTo(0, 0);
  }, []);

  const formatedDate = (createdAt) => {
    const date = new Date(createdAt.seconds * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const newDate = new Intl.DateTimeFormat("en-US", options).format(date);
    return newDate;
  };

  useEffect(() => {
    console.log("blosg", blogs);
  }, [blogs]);

  return (
    <>
      <Navbar />
      <div className="position-relative">
        {isGettingBlogs ? (
          <LoadingComp />
        ) : (
          <Container className="blogSection">
            <div style={{ marginTop: "10rem" }}>
              <div className="pt-3">
                <h1 className="font-family-2 text-center" style={{ fontWeight: '700' }}>Our Blog</h1>
                {/* <p className="fs--20 fw--400 ">You may be interested in this</p> */}
              </div>
              {blogs.length !== 0 ? (
                <Row className="py-3">
                  {blogs?.map((item) => {
                    return (
                      <>
                        {item.publish === true && (
                          <Col
                            lg="4"
                            className="my-3"
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(`/singleBlog/${item?.id}`);
                            }}
                          >
                            <Link
                              className="h-100 d-block"
                              to={`/whats-on/blog/${item.name}`}
                            >
                              <Card className="d-flex blogSection__card flex-column h-100">
                                <img
                                  className="img-fluid blogSection__inner__img"
                                  src={blogImage}
                                  alt="blog"
                                // src={item?.featuredImgURL}
                                />

                                <div className="blogSection__inner p-3">
                                  <CardBody className="p-0">
                                    <CardTitle className="fs--22 blogSection__inner__title fw--700 text-center">
                                      <p>{item?.title}</p>
                                    </CardTitle>

                                    <div className="">
                                      <CardText
                                        className="fs-16 fw--400 border-0 blogSection__inner__text py-3 text-center"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item?.description.slice(0, 120) +
                                            "...",
                                        }}
                                      ></CardText>
                                    </div>
                                  </CardBody>
                                </div>
                                <div className=" px-3 blogSection__button">
                                  <CardBody>
                                    <div className="d-flex align-items-center justify-content-center">
                                      {/* {item?.createdAt && (
                                        <CardText className="blogSection__card__date">
                                          {formatedDate(item?.createdAt)}
                                        </CardText>
                                      )} */}

                                      <button
                                        className="blogSection__inner__button text-center fs--16 fw--600"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          history.push(
                                            `/singleBlog/${item?.id}`
                                          );
                                        }}
                                      >
                                        Read More
                                      </button>
                                    </div>
                                  </CardBody>
                                </div>
                              </Card>
                            </Link>
                          </Col>
                        )}
                      </>
                    );
                  })}
                </Row>
              ) : (
                <div>No blogs at the moment </div>
              )}
            </div>
          </Container>
        )}
      </div>

      <Footer />
    </>
  );
};
export default BlogList;
