const initState = {
    notes: [],
    isLoading: false,
  };
  
  const datanotesReducer = (state = initState, { type, payload }) => {
    switch (type) {
      case "GET_NOTES":
        return {
          ...state,
          notes: payload,
        };
          case "SET_IS_LOADING":
            return {
              ...state,
              isLoading: payload,
            };
      default:
        return state;
    }
  };
  
  export default datanotesReducer;
  