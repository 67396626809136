const initState = {
  blogs: [],
  isGettingBlogs: false,
  loading: false,
};

const blogsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "SET_GET_BLOGS_LOADER":
      return {
        ...state,
        isGettingBlogs: payload,
      };
    case "FETCH_BLOGS":
      return {
        ...state,
        blogs: payload,
      };
    case "SET_BLOG_LOADING":
      return {
        ...state,
        loading: payload,
      };

    default:
      return state;
  }
};

export default blogsReducer;
