import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getHistory } from "../../Redux/Actions/historyAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "reactstrap";

const History = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  let { history } = useSelector((state) => state.history);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(getHistory(id));
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id]);

  return (
    <>
      <div className="d-flex align-items-center T-header">
        <h2 className="text-site-muted">History</h2>
      </div>
      <div className="T-contain">
        <table className="bg-white responsive">
          <thead>
            <tr>
              <th>Date</th>
              <th>TimeIn</th>
              <th>BreakStart</th>
              <th>BreakEnd</th>
              <th>TimeOut</th>
              <th>Status</th>
            </tr>
          </thead>
          {loading ? (
            <div className="d-flex align-items-center justify-content-center spinner-container width-100%">
              <Spinner color="primary" />
            </div>
          ) : (
            <tbody>
              {history &&
                history?.map((us) => (
                  <tr key={us?.id}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <h5 className="join-date">{us?.date}</h5>
                    </td>

                    <td>
                      <h5 className="join-date">
                        {moment.unix(us?.timeIn).format("HH:mm:ss")}
                      </h5>
                    </td>
                    <td>
                      <h5 className="join-date">
                        {moment.unix(us?.breakStart).format("HH:mm:ss")}
                      </h5>
                    </td>
                    <td>
                      <h5 className="join-date">
                        {moment.unix(us?.breakEnd).format("HH:mm:ss")}
                      </h5>
                    </td>
                    <td>
                      <h5 className="join-date">
                        {moment.unix(us?.timeOut).format("HH:mm:ss")}
                      </h5>
                    </td>
                    <td>
                      <h5 className="join-date">{us?.status}</h5>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default History;
