const initialState = {
  userEvents: [],
  eventsLoading: false,
};

export default function EventsReducers(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case "USER_EVENTS":
      return {
        ...state,
        userEvents: payload,
      };
    case "EVENT_LOADING":
      return {
        ...state,
        eventsLoading: payload,
      };

    default:
      return { ...state };
  }
}
