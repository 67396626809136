// chrome.exe --user-data-dir="C://Chrome dev session" --disable-web-security
import axios from "axios";
const baseDomain = "https://us-central1-work-chat-67aa9.cloudfunctions.net/";
const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});
export default axiosObj;
