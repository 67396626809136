const initialData = {
    verifyLoader: false,
};
const checkoutReducer = (state = initialData, action) => {
    switch (action.type) {
        case "VERIFY_LOADER":
            return {
                ...state,
                verifyLoader: action.payload,
            };
        default:
            return state;
    }
};

export default checkoutReducer;
