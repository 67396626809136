import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleBlog } from "../../Redux/Actions/blogsAction";
import { Col, Container, Row } from "reactstrap";
import { HashLink } from "react-router-hash-link";
import Footer from "../../components/global/Footer";
import Navbar from "../../components/global/Navbar";
import LoadingComp from "../../components/global/LoadingComp";
import { useDispatch, useSelector } from "react-redux";

const SingleBlog = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [singleBlog, setSingleBlog] = useState("");
    const { isGettingBlogs } = useSelector((state) => state.blogs);

 useEffect(() => {
   dispatch(fetchSingleBlog(id)).then((res) => {
     console.log("data at edit page ", res);
     setSingleBlog((pre) => ({
       ...pre,
       description: res.description,
       featuredImgURL: res.featuredImgURL,
       title: res.title,
       content: res.content,
       categoryId: res.categoryId,
       publish: res.publishId,
       tagId: res.tagId,
     }));

   });
 }, [id]);

  useEffect(() => {
    console.log("singleBlog at single ", singleBlog);
  }, [singleBlog]);

  return (
    <>
      <Navbar />
      <div>
        {isGettingBlogs ? (
          <LoadingComp />
        ) : (
          <Container className="singleBlog">
            <div className="">
              <HashLink
                key={`blogList`}
                className=""
                to="/blogsList"
                smooth={true}
              >
                <p className="fs--24 fw--600">View all our articles</p>
              </HashLink>
            </div>
            <div>
              <h1 className="singleBlog__title">{singleBlog?.title}</h1>
            </div>

            {/* <Row noGutters className="">
              <Col xs={12} className="">
                <img
                  className="img-fluid singleBlog__featureImage"
                  src={singleBlog?.featuredImgURL}
                  alt=""
                />
              </Col>
            </Row> */}

            {/* mine end */}

            <Row className="" noGutters>
              <Col xs={12} className="">
                <div className="my-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: singleBlog?.content,
                    }}
                  />
                </div>
              </Col>
            </Row>

            {/* <Row className="" noGutters>
              <Col xs={12} className="singleBlog__description">
                <div className="my-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: singleBlog?.description,
                    }}
                  />
                </div>
              </Col>
            </Row> */}
          </Container>
        )}
      </div>

      <Footer />
    </>
  );
};
export default SingleBlog;
