import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userVerified } from '../../Redux/Actions/authAction';
import { Redirect } from 'react-router-dom';
class Verify extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state={
            redirect:false,
        }
    }
  componentDidMount() {
    let id = window.location.pathname.split('/')[3];
    if (id) {
      console.log(id);
      this.props.userVerified(id);
    }
  }
  // componentDidUpdate(prevProps,prevState){
  //     if(this.props.verified!=prevProps.verified){
  //         return (
  //             <Redirect to='/auth/login' />
  //         )
  //     }
  // }
  render() {
    const { verified } = this.props;
    console.log(verified);
      if (verified)
      {
          setTimeout(() =>
              this.setState({ redirect: true })
              , 2000);
      }
      if (this.state.redirect)
      {
          return <Redirect to='/auth/login' />;
      }
    return (
      <>
        <h1>
          {verified ? 'Account Verified' : 'Account Verification Request'}
        </h1>
      </>
    );
  }
}
export const mapStateToProps = (state) => {
  return {
    verified: state.auth.veryfast,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    userVerified: (id) => dispatch(userVerified(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Verify);
