import firebase from "../../firebase";

export const addAnnouncement = (formData, uid) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SET_IS_LOADING", payload:true });
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(
        `announcement_images/${formData.image.name}`
      );
      await imageRef.put(formData.image);

      const imageUrl = await imageRef.getDownloadURL();

      const announcementData = {
        uid: uid,
        title: formData?.title,
        description: formData?.description,
        imageUrl: imageUrl,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };

      console.log(announcementData);

      const addData = await firebase
        .firestore()
        .collection("announcements")
        .add(announcementData);

        dispatch({ type: "SET_IS_LOADING", payload: false });
    } catch (error) {
      console.error("Error adding announcement:", error);
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };
};

export const getAnnouncement = (uid) => async (dispatch) => {
  console.log(uid, "jghgbbcghcc");
  try {
    dispatch({ type: "SET_IS_LOADING", payload:true });
    firebase
      .firestore()
      .collection("announcements")
      .where("uid", "==", uid)
      .orderBy("timestamp", "desc")
      .onSnapshot(async (data) => {
        let tempData = [];
        for (let doc of data.docs) {
          let data1 = doc.data();
          tempData.push({ id: doc.id, ...data1 });
        }
        console.log(tempData, "hamza>>>>>>");
        dispatch({ type: "GET_ANNOUNCEMENT_DATA", payload: tempData });
        dispatch({ type: "SET_IS_LOADING", payload: false });
      });
  } catch (error) {
    alert(error.message);
    dispatch({ type: "SET_IS_LOADING", payload:false });
  }
};

export const deleteAnnouncement = (announcementId) => async (dispatch) => {
  try {
    await firebase
      .firestore()
      .collection("announcements")
      .doc(announcementId)
      .delete();
    dispatch({ type: "DELETE_ANNOUNCEMENT", payload: announcementId });
  } catch (error) {
    console.log(error);
  }
};

export const getSingleAnnouncement = (modalid) => (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection("announcements")
      .doc(modalid);

    docRef.onSnapshot(async (doc) => {
      if (doc.exists) {
        const SingleAnnouncement = { id: doc.id, ...doc.data() };
        dispatch({
          type: "GET_SINGLE_ANNOUNCEMENT",
          payload: SingleAnnouncement,
        });
      } else {
        console.log("Document does not exist");
      }
    });
  } catch (error) {
    console.log(error);
  }
};
