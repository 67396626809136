import firebase from "../../firebase";

export const addDataNote = (formData) => {
  return async (dispatch) => {
    try {
      console.log(formData, "ahsaniqbalahsanhasnahans");
      const uidValues = formData?.uid?.map((item) => item?.value);
      const dataNoteData = {
        uid: uidValues,
        title: formData?.title,
        type: formData?.type,
        desc: [{ insert: formData?.desc }],
        createdBy: formData?.createdBy,
        updated: firebase.firestore.FieldValue.serverTimestamp(),
      };
      console.log(dataNoteData);

      const addData = await firebase
        .firestore()
        .collection("dataNotes")
        .add(dataNoteData);

        dispatch({ type: "SET_IS_LOADING", payload: false });
    } catch (error) {
      console.error("Error adding Note:", error);
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };
};

export const getNotes = () => async (dispatch) => {
    try {
      dispatch({ type: "SET_IS_LOADING", payload:true });
      firebase
        .firestore()
        .collection("dataNotes")
        .orderBy("updated", "desc")
        .onSnapshot(async (data) => {
          let tempData = [];
          for (let doc of data.docs) {
            let data1 = doc.data();
            if (data1.createdBy === "admin") {
              tempData.push({ id: doc.id, ...data1 });
            }
          }
          dispatch({ type: "GET_NOTES", payload: tempData });
          dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    } catch (error) {
      alert(error.message);
      dispatch({ type: "SET_IS_LOADING", payload:false });
    }
  };

export const updateDataNote = (formData) => {
  return async (dispatch) => {
    try {
        dispatch({ type: "SET_IS_LOADING", payload: true });
      console.log(formData, "<<<");
      const uidValues = formData?.uid?.map((item) => item?.value);

      let description =
        typeof formData?.desc === "string"
          ? [{ insert: formData?.desc }]
          : formData?.desc;

      const dataNoteData = {
        uid: uidValues,
        title: formData?.title,
        type: formData?.type,
        desc: description,
        createdBy: formData?.createdBy,
        updated: firebase.firestore.FieldValue.serverTimestamp(),
      };
      console.log("<<<", dataNoteData);

        const addData = await firebase
          .firestore()
          .collection("dataNotes")
          .doc(formData.id)
          .update(dataNoteData);

        dispatch({ type: "SET_IS_LOADING", payload: false });
    } catch (error) {
      console.error("Error adding Note:", error);
      dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };
};

export const updateStatus = (id, status) => {
  return async (dispatch) => {
    try {
      const addData = await firebase
        .firestore()
        .collection("dataNotes")
        .doc(id)
        .update({
          type: status,
        });

    } catch (error) {
      console.error("Error adding Note:", error);
    }
  };
};

export const deleteTodo = (id) => {
  return async (dispatch) => {
    try {
      const addData = await firebase
        .firestore()
        .collection("dataNotes")
        .doc(id)
        .delete();

      //   dispatch({ type: "SET_IS_LOADING", payload: false });
    } catch (error) {
      console.error("Error adding Note:", error);
      // dispatch({ type: "SET_IS_LOADING", payload: false });
    }
  };
};
