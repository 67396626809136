import React, { Component } from "react";
import { Button, Collapse, Container, NavbarToggler } from "reactstrap";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Main extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    if (this.props.uid == "" || !this.props.uid || this.props.uid == null) {
      console.log("Going to redirect to login");
      return <Redirect to="/auth/login" />;
    }
    return (
      <>
        <main className="dashboard-page">
          <Header onClick={this.toggle} />
          <section className="d-flex p-0 position-relative">
            <Sidebar open={this.state.isOpen} />
            <div className="h-100 w-100 py-4" style={{ overflowY: "scroll" }}>
              <Container className="h-100 position-relative">
                {this.props.children}
              </Container>
            </div>
          </section>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uid: state.auth.uid,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
