import React, { Component, useState, useEffect } from "react";
import { Button, Card, Form, Input, Label } from "reactstrap";
import user from "../../assets/img/dashboard-Slicing/Users Name.png";
import { getUsers, sendMesssage } from "../../Redux/Actions/userAction";
import { useSelector, useDispatch } from "react-redux";

function Smessage() {
  let [message, setMessage] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allSelect, setAllSelect] = useState(false);

  let dispatch = useDispatch();

  let { myUsers } = useSelector((state) => state.user);
  let { uid } = useSelector((state) => state.auth);

  const handleUserChange = (e) => {
    let newArray = [...selectedUsers, e.target.value];
    if (selectedUsers.includes(e.target.value)) {
      newArray = newArray.filter((mUser) => mUser !== e.target.value);
    }
    setSelectedUsers(newArray);
  };

  const selectAllChange = (e) => {
    if (allSelect == true) {
      setSelectedUsers([]);
      setAllSelect(!allSelect);
    } else {
      if (myUsers && myUsers.length > 0) {
        let newArray = [];
        myUsers.map((user) => {
          newArray.push(user.id);
        });
        setSelectedUsers(newArray);
        setAllSelect(!allSelect);
      }
    }
  };

  const isUserSelected = (id) => {
    let obj = selectedUsers.find((user) => user == id);
    if (obj != undefined) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (uid) {
      dispatch(getUsers(uid));
    }
  }, []);

  console.log("All Selected", selectedUsers);

  return (
    <div>
      <Form
        type="submit"
        className="message-input"
        onSubmit={(e) => {
          e.preventDefault();
          if (selectedUsers.length > 0) {
            let obj = {
              message: message,
              user_ids: selectedUsers,
            };
            dispatch(sendMesssage(obj));
            setMessage("");
            setAllSelect(false);
          } else {
            alert("Select a user!");
          }
        }}
      >
        <Input
          type="text"
          name="text"
          id="exampleText"
          placeholder="Type your message..."
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button color="site-primary" type="submit">
          Send
        </Button>
      </Form>
      <h3 className="text-site-muted mb-4 mt-2">Select Users</h3>
      <div className="S-users">
        {myUsers && myUsers.length > 0 ? <div className="T-contain">
          <table className="bg-white">
            <tr>
              <th>Users</th>
              <th>Phone Number</th>
              <th className="select-user">
                <span className='d-flex align-items-center justify-content-end m-0 p-0' style={{ gap: '0.5rem' }}>
                  All
                  <Input
                    type="checkbox"
                    className="option-input checkbox"
                    onChange={selectAllChange}
                  />
                </span>
              </th>
            </tr>
            {myUsers.map((myUser) => {
              console.log(myUser);
              return (
                <tr>
                  <td>
                    <div className="profile-detail">
                      <img src='https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg' alt="profile-image" />
                      <h5>{myUser?.name}</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="join-date">
                      {myUser?.email || myUser?.PhoneNumber}
                    </h5>
                  </td>
                  <td className="select-user">
                    <Input
                      type="checkbox"
                      id="checkbox1"
                      className="option-input checkbox"
                      onChange={handleUserChange}
                      checked={isUserSelected(myUser.id)}
                      value={myUser.id}
                    />
                  </td>
                </tr>
              );
            })}
          </table>

        </div> : "Users Not Available!"}

        {/* {myUsers && myUsers.length > 0
          ? myUsers.map((myUser) => {
            console.log(myUser);
            return (
              <Card className="shadow-sm border-0 users">
                <Input
                  type="checkbox"
                  id="checkbox1"
                  className="option-input checkbox"
                  onChange={handleUserChange}
                  checked={isUserSelected(myUser.id)}
                  value={myUser.id}
                />
                <div className="profile-avatar">
                  <img src={user} alt="icon" />
                </div>
                <h5>{myUser?.PhoneNumber}{myUser?.email || myUser?.PhoneNumber}</h5>
              </Card>
            );
          })
          : "Users Not Available!"} */}

        {/* <Card className="shadow-sm border-0 users">
          <Input
            type="checkbox"
            id="checkbox1"
            className="option-input checkbox"
          />
          <div className="profile-avatar">
            <img src={user} alt="icon" />
          </div>
          <h5>User Research</h5>
        </Card> */}
      </div>
    </div>
  );
}

export default Smessage;
