import { Link, useHistory, Redirect, NavLink } from "react-router-dom";
import { Button, Input, Navbar as RC_Navbar } from "reactstrap";
import search from "../../assets/img/dashboard-Slicing/Search here.png";
import bell from "../../assets/img/dashboard-Slicing/notification.png";
import user from "../../assets/img/dashboard-Slicing/Users Name.png";
import lgout from "../../assets/img/dashboard-Slicing/Logout.svg";
import { logout } from "../../Redux/Actions/authAction";
import { connect } from "react-redux";
import { useEffect } from "react";
import { logoutComplete } from "../../Redux/Actions/userAction";
import Logo from "../../assets/img/Landing page-slicing/Logo_latest.png";

const Sidebar = (props) => {
  let history = useHistory();
  let auth = JSON.parse(localStorage.getItem("auth"));

  // 	useEffect(() => {
  // console.log(history.location.pathname)
  //   }, [history.location.pathname]);
  // if (props.uid == '') {
  //   return <Redirect to="/auth/login" />;
  // }

  return (
    <>
      <div
        className={`side-drawer bg-white position-relative ${
          props.open ? "hide-side-drawer" : ""
        }`}
      >
        <div className="nav-item-list w-100 px-3">
          <h3>
            Welcome Back 👋 <br />
            {auth && auth.user.name}
          </h3>
          <NavLink
            to="/admin/index"
            activeClassName="active-item"
            className="nav-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 640 512"
            >
              <path d="M128 32C92.7 32 64 60.7 64 96V352h64V96H512V352h64V96c0-35.3-28.7-64-64-64H128zM19.2 384C8.6 384 0 392.6 0 403.2C0 445.6 34.4 480 76.8 480H563.2c42.4 0 76.8-34.4 76.8-76.8c0-10.6-8.6-19.2-19.2-19.2H19.2z" />
            </svg>
            <span>Dashboard</span>
          </NavLink>
          <NavLink
            to="/admin/profile"
            activeClassName="active-item"
            className="nav-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" />
            </svg>
            <span>Profile</span>
          </NavLink>
          <NavLink
            to="/admin/announcements"
            activeClassName="active-item"
            className="nav-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-megaphone"
              viewBox="0 0 16 16"
            >
              <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 75 75 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 0 0-1.722-.082z" />
            </svg>
            <span>Announcements</span>
          </NavLink>
          <NavLink
            to="/admin/chat"
            activeClassName="active-item"
            className="nav-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z" />
            </svg>
            <span>Message</span>
          </NavLink>

          <NavLink
            to="/admin/datanotes"
            activeClassName="active-item"
            className="nav-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120z" />
            </svg>
            <span>DataNotes</span>
          </NavLink>

          <NavLink
            to="/admin/invoice"
            activeClassName="active-item"
            className="nav-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120z" />
            </svg>
            <span>Invoice</span>
          </NavLink>
          <NavLink
            to="/admin/refer"
            activeClassName="active-item"
            className="nav-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              viewBox="0 0 640 512"
            >
              <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
            </svg>
            <span>Referral code</span>
          </NavLink>
        </div>
        <div className="logout-btn p-3 position-absolute w-100 border-top">
          <Button
            variant="danger"
            className="btn btn-danger w-100"
            onClick={() => {
              props.logoutComplete();
              props.authlogout();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
              fill="white"
              className="mr-2 logout-icon"
            >
              <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
            </svg>
            Logout
          </Button>
        </div>
      </div>
    </>
  );
};

export function mapStateToProps(state) {
  return { uid: state.auth.uid };
}

export function mapDisptachToProps(dispatch) {
  return {
    authlogout: () => dispatch(logout()),
    logoutComplete: () => dispatch(logoutComplete()),
  };
}

export default connect(mapStateToProps, mapDisptachToProps)(Sidebar);
