const initState = {
    announcments: [],
    singleAnnouncment: [],
    isLoading: false,
  };
  
  const announcementReducer = (state = initState, { type, payload }) => {
    switch (type) {
      case "GET_ANNOUNCEMENT_DATA":
        return {
          ...state,
          announcments: payload,
        };
        case "GET_SINGLE_ANNOUNCEMENT":
          return {
            ...state,
            singleAnnouncment: payload,
          };
          case "SET_IS_LOADING":
            return {
              ...state,
              isLoading: payload,
            };
      default:
        return state;
    }
  };
  
  export default announcementReducer;
  