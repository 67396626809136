import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import close from "../../assets/img/dashboard-Slicing/close.svg";
import Auser from "../../assets/img/dashboard-Slicing/new-user.png";
import user from "../../assets/img/dashboard-Slicing/Users Name.png";
import { Spinner } from "reactstrap";
import {
  addAnnouncement,
  deleteAnnouncement,
  getAnnouncement,
  getSingleAnnouncement,
} from "../../Redux/Actions/announcmentAction";

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    const truncatedText = text.substr(0, maxLength);
    return `${truncatedText}...`;
  }
}

const Announcement = () => {
  const dispatch = useDispatch();
  const { announcments, singleAnnouncment,isLoading } = useSelector(
    (state) => state.announcement
  );
  const { uid } = useSelector((state) => state.auth);
  console.log(uid, "hamzazia");

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
  });

  const toggle = () => {
    setModal(!modal);
  };
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);

  const handleDelete = (scheduleId) => {
    dispatch(deleteAnnouncement(scheduleId));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const file = e.target.type === "file" ? e.target.files[0] : null;

    setFormData((prevData) => ({
      ...prevData,
      [name]: file || value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(addAnnouncement(formData, uid));
    console.log(formData);
    toggle();
    setFormData({
      title: "",
      description: "",
      image: "",
    });
  };

  useEffect(() => {
    dispatch(getAnnouncement(uid));
  }, []);

  return (
    <>
      <div className="d-flex align-items-center T-header">
        <h2 className="text-site-muted">Announcments</h2>
        <Button color="site-primary" className="px-4 py-2" onClick={toggle}>
          Add New
        </Button>
      </div>
      <div className="T-contain">
        {isLoading && (
          <div className="d-flex align-items-center justify-content-center spinner-container width-100%">
            <Spinner color="primary" />
          </div>
        )}
        {!isLoading && (
          <table className="bg-white responsive">
          <tr>
            <th>Date</th>
            <th>Title</th>
            <th>Description</th>
            <th>Image Url</th>
            <th>Action</th>
          </tr>
          {announcments &&
            announcments.map((us, index) => (
              <tr key={index}>
                <td style={{ whiteSpace: "nowrap" }}>
                  <h5 className="join-date">
                    {moment
                      .unix(us?.timestamp?.seconds)
                      .format("MMMM DD, YYYY")}
                  </h5>
                </td>
                <td>
                  <h5 className="join-date">{us?.title}</h5>
                </td>
                <td>
                  <h5 className="join-date">
                    {truncateText(us?.description, 30)}
                  </h5>
                </td>
                <td>
                  <h5 className="join-date">
                    <img
                      src={us?.imageUrl}
                      className="rounded d-block"
                      height={"100px"}
                      width={"150px"}
                      alt={us?.title}
                    ></img>
                  </h5>
                </td>

                <td>
                  <h5 className="d-flex m-0 p-0">
                    <Button
                      color="site-primary"
                      onClick={() => {
                        dispatch(getSingleAnnouncement(us?.id));
                        toggle2();
                      }}
                    >
                      View
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      // onClick={() => handleDelete(us?.id)}
                      onClick={() => {
                        dispatch(getSingleAnnouncement(us?.id));
                        toggle3();
                      }}
                    >
                      Remove
                    </Button>
                  </h5>
                </td>
              </tr>
            ))}
        </table>
        )}
        

        {/* //Add form modal  */}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody className="p-4 mx-2 overflow-hidden ">
            <div className="text-right d-flex">
              <span className="ml-auto mt-auto mb-auto" onClick={toggle}>
                <img src={close} alt="icon" width="40px" className="close-AM" />
              </span>
            </div>
            <div className="text-center" style={{ marginTop: "-50px" }}>
              <img src={Auser} alt="icon" width="50%" className="mb-3" />
              <h2 className="py-2 pb-3">Add New Schedule</h2>
              <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-2">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Add Title"
                      type="text"
                      name="title"
                      id="selectDate"
                      value={formData.title}
                      onChange={handleInputChange}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-2">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Add description"
                      type="text"
                      name="description"
                      id="selectClockIn"
                      value={formData.description}
                      onChange={handleInputChange}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-2">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <input
                      type="file"
                      name="image"
                      id="selectClockOut"
                      onChange={handleInputChange}
                      required
                      accept="image/*"
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0 w-100"
                    />
                  </div>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-3 py-3 w-100 bg-site-primary shadow"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>

        {/* detail modal  */}

        <Modal isOpen={modal2} toggle={toggle2}>
          {/* <ModalHeader>
          </ModalHeader> */}
          <div className="d-flex justify-content-between w-100 space-between">
            <h5 className="ml-3 p-0 m-0">
              <strong>{singleAnnouncment?.title}</strong>
            </h5>
            <p className="mr-3 p-0 m-0">
              {moment
                .unix(singleAnnouncment?.timestamp?.seconds)
                .format("MMMM DD, YYYY")}
            </p>
          </div>

          <ModalBody>
            <h4>Description:</h4>
            <p className="ml-3">{singleAnnouncment?.description}</p>
            <h4>Image:</h4>
            <p className="ml-3">
              <img
                src={singleAnnouncment?.imageUrl}
                className="rounded d-block"
                height={"200px"}
                width={"auto"}
                alt={singleAnnouncment?.title}
              ></img>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="site-primary" onClick={toggle2}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {/* Delete conformation  */}

        <Modal isOpen={modal3} toggle={toggle3}>
          <ModalBody>
            <div className="p-3">
              <h4>Do you want to delete this Announcement?</h4>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="site-primary" onClick={toggle3}>
              Cancel
            </Button>{" "}
            <Button
              color="danger"
              onClick={() => {
                handleDelete(singleAnnouncment?.id);
                toggle3();
              }}
            >
              Comfirm
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default Announcement;
