import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import "./Profile.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import Company from "../../assets/img/dashboard-Slicing/Company Name.png";
import email from "../../assets/img/dashboard-Slicing/Email.png";
import password from "../../assets/img/dashboard-Slicing/Password.png";
import logo from "../../assets/img/dashboard-Slicing/Logo 3.png";
import visalogo from "../../assets/img/dashboard-Slicing/Visa_Inc._logo.svg.png";
import selected from "../../assets/img/dashboard-Slicing/selected.png";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { updateData } from "../../Redux/Actions/authAction";
import { changeLogo } from "../../Redux/Actions/userAction";
import {
  fetchUsers,
  addCustomer,
  fetchCards,
  removeCustomer,
} from "../../Redux/Actions/userAction";
import StripeCard from "../../components/StripeCard";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      Currentpassword: "",
      password: "",
      Confirmpassword: "",
      cardName: "",
      cardNumber: "",
      expiry: "",
      cvc: "",
      imageModal: false,
      logo: "",
    };
  }

  async componentDidMount() {
    let { myUsers, userData, uid } = this.props;
    await this.props.fetchUsers(uid);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.customer == null) {
      this.props.fetchCards(this.props.userData.customer_id);
    }
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  imageToggle = () => {
    this.setState({ imageModal: !this.state.imageModal });
  };

  render() {
    const { auth, uid, loading, id, myUsers, addCustomer } = this.props;


    const CustomInput = (props) => {
      return (
        <input
          // className={[classes.TransparentInput, "uk-input"].join(" ")}
          className=" py-3 px-2 shadow border-0"
          onClick={props.onClick}
          value={props.value}
          type="text"
          placeholder="Enter Expiry Date"
          readOnly={true}
        />
      );
    };



    return (
      <>
        {/* Change Logo Modal */}

        <Modal toggle={this.imageToggle} isOpen={this.state.imageModal}>
          <ModalHeader toggle={this.imageToggle}>Change Logo</ModalHeader>
          <ModalBody>
            <input
              type="file"
              name="myImage"
              accept="image/*"
              onChange={(e) => this.setState({ logo: e.target.files[0] })}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.imageToggle}>Cancel</Button>
            <Button
              className="bg-site-primary"
              onClick={async () => {
                if (this.state.logo != "") {
                  let obj = { img: this.state.logo };
                  await this.props.changeLogo(obj, uid);
                  this.imageToggle();
                } else {
                  alert("Selet image!");
                }
              }}
            >
              {this.props.loading ? <Spinner size="sm" /> : "Upload"}
            </Button>
          </ModalFooter>
        </Modal>

        <div className="profile-page-heading">
          <h3>
            Profile Page
          </h3>
          <p>
            Edit you Profile and Payment info here
          </p>
        </div>

        <div className="profile-setting-page w-100">
          <div className="forms" style={{ flex: 1 }}>
            <div className="paper bg-white border p-3 profile-image w-100">
              <div className='d-flex' style={{ gap: '0.5rem' }}>
                <img src={logo} alt="icon" />
                <div>
                  <h6>{myUsers.name}</h6>
                  <p>{myUsers.email}</p>
                </div>
              </div>
              <Button
                className="w-100 bg-site-primary mt-1 mb-2"
                onClick={this.imageToggle}
              >
                Change Logo
              </Button>
            </div>
            <div className="paper bg-white border w-100">
              <h6 className='border-bottom p-3 font-weight-bold'>Change Password</h6>
              <Form
                role="form"
                className='p-3'
                onSubmit={(e) => {
                  e.preventDefault();
                  let credentials;
                  if (this.state.password != this.state.Confirmpassword) {
                    alert("Password and Confirm Password not matched!");
                  } else {
                    credentials = {
                      Currentpassword: this.state.Currentpassword,
                      password: this.state.password,
                      Confirmpassword: this.state.Confirmpassword,
                    };
                    this.props.updateData(myUsers.id, credentials);
                    this.setState({
                      Currentpassword: "",
                      password: "",
                      Confirmpassword: "",
                    });
                  }
                }}
              >
                <p>Current Password</p>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={password} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="Current Password"
                      name="Currentpassword"
                      onChange={this.handleInput}
                      type="password"
                      required
                      value={this.state.Currentpassword}
                      className="pl-5 py-4"
                    />
                  </div>
                </FormGroup>
                <p>New Password</p>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={password} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="New Password"
                      // defaultValue={this.state.password}
                      onChange={this.handleInput}
                      name="password"
                      type="password"
                      value={this.state.password}
                      required
                      className="pl-5 py-4"
                    />
                  </div>
                </FormGroup>
                <p>Confirm Password</p>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={password} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="Confirm Password"
                      // defaultValue={this.state.Confirmpassword}
                      onChange={this.handleInput}
                      name="Confirmpassword"
                      type="password"
                      value={this.state.Confirmpassword}
                      required
                      className="pl-5 py-4"
                    />
                  </div>
                </FormGroup>
                <Button
                  className="w-100 bg-site-primary mt-3 mb-2"
                  type="submit"
                >
                  {/* <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                    <path d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z" />
                  </svg> */}
                  Save
                </Button>
              </Form>
            </div>
          </div>
          <div className="paper bg-white border" style={{ flex: 1, height: 'max-content' }}>
            <h6 className='border-bottom p-3 font-weight-bold'>Payment Method</h6>
            <StripeCard />
          </div>
        </div>

        {/* <Row lg="2" md="2" sm="1" xs="1"> */}
        {/* <Col>
            <Card className="border-0 shadow w-100 h-100 mx-2 mb-3 mb-lg-0 py-4 px-lg-5 px-3">
              <div className="d-flex my-3">
                <img src={logo} alt="icon" width="20%" />
                <div className="pl-2 my-auto">
                  <h4 className="mb-0">
                    <strong>Work Chat</strong>
                  </h4>

                  <a className="C-logo" onClick={this.imageToggle}>
                    Change Logo
                  </a>
                </div>
              </div>
              <Form
                role="form"
                className="card-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  let credentials;
                  if (this.state.password != this.state.Confirmpassword) {
                    alert("Password and Confirm Password not matched!");
                  } else {
                    credentials = {
                      Currentpassword: this.state.Currentpassword,
                      password: this.state.password,
                      Confirmpassword: this.state.Confirmpassword,
                    };
                    this.props.updateData(myUsers.id, credentials);
                    this.setState({
                      Currentpassword: "",
                      password: "",
                      Confirmpassword: "",
                    });
                  }
                }}
              >
                <FormGroup className="mb-3">
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={Company} alt="icon" width="16px" />
                    </div>
                    <Input
                      placeholder="Company Name"
                      value={myUsers.name}

                      readOnly
                      name="name"
                      type="text"

                      className="pl-5 py-4"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-3">
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={email} alt="icon" width="16px" />
                    </div>
                    <Input
                      value={myUsers.email}
                      placeholder="Email"

                      name="email"
                      type="email"
                      readOnly

                      autoComplete="new-email"
                      className="pl-5 py-4"
                    />
                  </div>
                </FormGroup>
                <h6>
                  <strong>Change Password</strong>
                </h6>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={password} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="Current Password"
                      name="Currentpassword"
                      onChange={this.handleInput}
                      type="password"
                      required
                      value={this.state.Currentpassword}
                      className="pl-5 py-4"
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={password} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="New Password"
             
                      onChange={this.handleInput}
                      name="password"
                      type="password"
                      value={this.state.password}
                      required
                      className="pl-5 py-4"
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="position-relative form-box">
                    <div className="position-absolute form-icon" left="10">
                      <img src={password} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="Confirm Password"
             
                      onChange={this.handleInput}
                      name="Confirmpassword"
                      type="password"
                      value={this.state.Confirmpassword}
                      required
                      className="pl-5 py-4"
                    />
                  </div>
                </FormGroup>
                <div className="text-center mt-auto">
                  <Button
                    className="mb-3 w-100 bg-site-primary shadow py-3"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Card>
          </Col> */}
        <Col>
          {/* <Card className=" border-0 shadow w-100 h-100 mx-2 mt-3 mt-md-5 mt-lg-0 py-4 px-lg-5 px-3">
              <div className=" mx-auto">
                <h4 className="mt-2 mb-4 text-center">
                  <strong>Add Payment Method</strong>
                </h4>
              </div>
              {this.props.customer != null ? (
                <>
                  <Card className="border-0 shadow w-100 my-2">
                    <div className="d-flex my-1 ml-2">
                      <img
                        src={visalogo}
                        alt="icon"
                        width="7%"
                        className="my-auto mx-3"
                      />
                      <div className="pl-2 my-auto">
                        <div className="mb-n2">
                          <small className="mb-0 font-weight-bold">
                            {this.props.customer.name}
                          </small>
                        </div>
                        <small className="text-site-muted">
                          {"XXXXXXXXXX" + this.props.customer.last4}
                        </small>
                      </div>
                      <div className="ml-auto my-auto mr-3">
                        <img
                          src={selected}
                          alt="icon"
                          //   width="7%"
                        />
                      </div>
                    </div>
                  </Card>
                  <div className="ml-auto">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.props.removeCustomer(
                          this.props.customer.customerId,
                          uid
                        );
                      }}
                      className="text-site-muted text-right mb-3"
                    >
                      <strong>Remove</strong>
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <h6>
                <strong>Payment Method</strong>
              </h6>
              <Form
                role="form"
                className="card-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  let obj = {
                    name: this.state.cardName,
                    cardNo: this.state.cardNo,
                    expiryDate: moment(this.state.expiry).format("YYYY-MM"),
                    cvc: this.state.cvc,
                  };
                  console.log("obj", obj, uid);
                  addCustomer(obj, uid).then((res) => {
                    this.setState({
                      cardName: "",
                      cardNo: "",
                      cvc: "",
                      expiry: "",
                    });
                  });
                }}
              >
                <FormGroup className="mb-3">
                  <div className="position-relative form-box">
                    <Input
                      placeholder="Card Name"
                      name="name"
                      type="name"
                      value={this.state.cardName}
                      required
                      onChange={(e) =>
                        this.setState({ cardName: e.target.value })
                      }
                      className=" py-4 shadow border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="position-relative form-box">
                    <Input
                      placeholder="Card Number"
                      name="number"
                      type="number"
                      minLength="16"
                      maxLength="16"
                      value={this.state.cardNo}
                      onChange={(e) =>
                        this.setState({ cardNo: e.target.value })
                      }
                      required
                      className=" py-4 shadow border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="d-md-flex d-block">
                  <DatePicker
                    customInput={<CustomInput />}
                    placeholder="Enter Expiry Date"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    selected={this.state.expiry}
                    onChange={(date) => this.setState({ expiry: date })}
                  />
                  
                  <Input
                    placeholder="CVV"
                    type="number"
                    name="number"
                    minLength="3"
                    maxLength="3"
                    value={this.state.cvc}
                    onChange={(e) => this.setState({ cvc: e.target.value })}
                    required
                    className=" py-4 ml-md-2 ml-0 shadow border-0"
                  />
                </FormGroup>
                <div className="text-center mt-auto">
                  <Button
                    className="mb-3 w-100 bg-site-primary shadow register-btn"
                    type="submit"
                  >
                    {loading ? <Spinner size="sm" /> : "Add"}
                  </Button>
                </div>
              </Form>
            </Card> */}
        </Col>
        {/* </Row> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myUsers: state.user.users,
    uid: state.auth.uid,
    loading: state.user.loading,
    userData: state.user.users,
    customer: state.user.customer,
    loading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateData: (id, credentials) => dispatch(updateData(id, credentials)),
    fetchUsers: (id) => dispatch(fetchUsers(id)),
    addCustomer: (obj, uid) => dispatch(addCustomer(obj, uid)),
    fetchCards: (id) => dispatch(fetchCards(id)),
    removeCustomer: (c_id, id) => dispatch(removeCustomer(c_id, id)),
    changeLogo: (obj, uid) => dispatch(changeLogo(obj, uid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
