import React, { useEffect } from "react";
import Footer from "../../components/global/Footer";
import Navbar from "../../components/global/Navbar";
import { Container } from "reactstrap";

function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />

      <div className="bg-white py-5">
        <Container className="py-5 h">
          {" "}
          <strong>Merk Terms of Use</strong>
          <p>
            <strong>Effective Date:</strong> June 23, 2023
          </p>
          <p>
            Please read these Terms of Use ("Terms") carefully before accessing
            or using the Merk website ("Website") and any services provided
            by Merk ("Service"). By accessing or using the Website and
            Service, you agree to be bound by these Terms. If you do not agree
            to these Terms, you may not access or use the Website and Service.
          </p>
          <strong>1. Acceptance of Terms</strong>
          <p className="pl-5">
            By accessing or using the Website and Service, you represent and
            warrant that you are at least 18 years old and have the legal
            authority to enter into these Terms. If you are accessing or using
            the Website and Service on behalf of a company or organization, you
            represent and warrant that you have the authority to bind that
            entity to these Terms.
          </p>
          <strong>2. License</strong>
          <p className="pl-5">
            Subject to your compliance with these Terms, Merk grants you a
            limited, non-exclusive, non-transferable, revocable license to
            access and use the Website and Service for your personal or internal
            business use.
          </p>
          <strong>3. User Responsibilities</strong>
          <p className="pl-5">
            (a) You are solely responsible for your use of the Website and
            Service, including any content you submit or transmit through the
            Website and Service.
            <br /> (b) You agree not to use the Website and Service for any
            unlawful, infringing, defamatory, harmful, or otherwise
            objectionable purpose.
            <br /> (c) You agree not to engage in any activity that could
            disable, overburden, or impair the Website and Service or interfere
            with any other party's use of the Website and Service.
            <br />
            (d) You agree not to attempt to gain unauthorized access to any part
            of the Website and Service or any other systems or networks
            connected to the Website and Service.
          </p>
          <strong>4. Intellectual Property</strong>
          <p className="pl-5">
            (a) The Website and Service, including all content, features, and
            functionality, are owned by Merk or its licensors and are
            protected by intellectual property laws.
            <br /> (b) You may not modify, reproduce, distribute, create
            derivative works of, publicly display or perform, or in any way
            exploit any part of the Website and Service without prior written
            permission from Merk.
          </p>
          <strong>5. User Content</strong>
          <p className="pl-5">
            (a) Merk does not endorse or control the content posted or
            transmitted through the Website and Service and disclaims any
            responsibility for such content.
            <br /> (b) You understand that by using the Website and Service, you
            may be exposed to content that may be offensive, harmful, or
            inaccurate, and you agree to use the Website and Service at your own
            risk.
          </p>
          <strong>6. Privacy</strong>
          <p className="pl-5">
            Your privacy is important to us. Please refer to our Privacy Policy,
            which is incorporated by reference into these Terms, for information
            on how we collect, use, and disclose your personal data.
          </p>
          <strong>7. Disclaimer of Warranties</strong>
          <p className="pl-5">
            THE WEBSITE AND SERVICE ARE PROVIDED ON AN "AS IS" AND "AS
            AVAILABLE" BASIS, WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS,
            IMPLIED, OR STATUTORY. Merk DISCLAIMS ALL WARRANTIES,
            INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT.
          </p>
          <strong>8. Limitation of Liability</strong>
          <p className="pl-5">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Merk SHALL
            NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OR
            INABILITY TO USE THE WEBSITE AND SERVICE, EVEN IF Merk HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <strong>9. Indemnification</strong>
          <p className="pl-5">
            You agree to indemnify and hold Merk and its affiliates,
            officers, directors, employees, and agents harmless from and against
            any claims, liabilities, damages, losses, and expenses, including
            reasonable attorneys' fees, arising out of or in any way connected
            with your use of the Website and Service or violation of these
            Terms.
          </p>
          <strong>10. Modifications and Termination</strong>
          <p className="pl-5">
            Merk reserves the right to modify, suspend, or terminate the
            Website and Service, in whole or in part, at any time and for any
            reason without prior notice. Merk may also modify these Terms
            at any time, and such modifications will be effective immediately
            upon posting. Your continued use of the Website and Service after
            the posting of any modifications constitutes your acceptance of the
            modified Terms.
          </p>
          <strong>11. Governing Law and Jurisdiction</strong>
          <p className="pl-5">
            These Terms shall be governed by and construed in accordance with
            the laws of Michigan, United States, without regard to its conflict
            of law principles. Any legal action or proceeding arising out of or
            in connection with these Terms shall be exclusively brought in the
            state or federal courts located in Michigan, and you consent to the
            personal jurisdiction of such courts.
          </p>
          <strong>12. Entire Agreement</strong>
          <p className="pl-5">
            These Terms constitute the entire agreement between you and Merk regarding the use of the Website and Service and supersede any
            prior or contemporaneous agreements, communications, and proposals,
            whether oral or written, between you and Merk.
          </p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default TermsOfUse;
