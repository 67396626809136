import React, { Component, createContext } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import user from "../../assets/img/dashboard-Slicing/Users Name.png";
import Auser from "../../assets/img/dashboard-Slicing/new-user.png";
import msg from "../../assets/img/dashboard-Slicing/Email.png";
import phone from "../../assets/img/dashboard-Slicing/Phone.png";
import close from "../../assets/img/dashboard-Slicing/close.svg";
import moment from "moment";
// import { mapStateToProps } from "../auth/Login";
import { connect } from "react-redux";
import {
  addUsers,
  deleteUsers,
  getCompany,
  getUsers,
} from "../../Redux/Actions/userAction";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      modal2: false,
      isOpen: false,
      name: "",
      email: "",
      PhoneNumber: "",
      jobTitle: "",
      department: "",
      id: "",
      // companyId: "",
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2,
    });
  };
  toggle1 = () => {
    this.setState({
      modal1: !this.state.modal1,
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.props.getUsers(this.props.uid);
    this.props.getCompany(this.props.uid);
  }
  //   componentWillUpdate(prevProps) {
  //     if (prevProps.myUsers != this.props.myUsers) {
  //       this.props.getUsers(this.props.uid);
  //     }
  //   }

  render() {
    const { uid, myUsers, myCompanies } = this.props;

    console.log(uid, myUsers, "Ahsaniqbal");

    return (
      <>
        <div className="d-flex align-items-center T-header">
          <h2 className="text-site-muted">
            OUR USERS <strong>({myUsers.length})</strong>
          </h2>
          <Button
            color="site-primary"
            className="px-4 py-2"
            onClick={this.toggle}
          >
            Add New
          </Button>
        </div>

        <div className="T-contain">
          <table className="bg-white">
            <tr>
              <th>Users</th>
              <th>Joined Date</th>
              <th>Phone Number</th>
              <th>Action</th>
            </tr>
            {myUsers &&
              myUsers.map((us) => (
                <tr key={us.id}>
                  <td>
                    <div className="profile-detail">
                      <img
                        src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                        alt="profile-image"
                      />
                      <h5>{us.name}</h5>
                    </div>
                  </td>
                  <td>
                    <h5 className="join-date" style={{ whiteSpace: "nowrap" }}>
                      {moment
                        .unix(us.created_datetime.seconds)
                        .format("YYYY-MM-DD h:mm:ss a")}
                    </h5>
                  </td>
                  <td>
                    <h5 className="join-date">
                      {us?.email || us?.PhoneNumber}
                    </h5>
                  </td>
                  <td className="join-date">
                    <h5 className="d-flex m-0 p-0">
                      <Link to={`/admin/events/${us?.id}`}>
                        <Button color="site-primary">Add Events</Button>
                      </Link>
                      <Link to={`/admin/history/${us?.id}`}>
                        <Button color="site-primary" className="ml-2">
                          History
                        </Button>
                      </Link>
                      <Link to={`/admin/schedule/${us?.id}`}>
                        <Button color="site-primary" className="ml-2">
                          schedule
                        </Button>
                      </Link>
                      <Button
                        color="danger"
                        className="ml-2"
                        onClick={(e) => {
                          this.setState({ id: us.id });
                          this.toggle1();
                        }}
                      >
                        Remove
                      </Button>
                    </h5>
                  </td>
                </tr>
              ))}
          </table>
          {/* {myUsers &&
            myUsers.map((us) => (
              <Card key={us.id} className="T-data shadow-sm">
                <Row lg="3" xs="3">
                  <Col className="d-flex">
                    <div className="profile-avatar">
                      <img src={user} alt="icon" />
                    </div>
                    <h5>{us.name}</h5>
                  </Col>

                  <Col className="m-auto">
                    <h5 className="text-site-muted text-center">
                      {moment
                        .unix(us.created_datetime.seconds)
                        .format("YYYY-MM-DD h:mm:ss a")}
                    </h5>
                  </Col>
                  <Col className="m-auto mr-0 text-right">
                    <Button
                      color="danger"
                      onClick={(e) => {
                        this.setState({ id: us.id });
                        this.toggle1();
                      }}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              </Card>
            ))} */}
        </div>
        {/* /////Add new User Modal */}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody className="p-4 mx-2">
            <div className="text-right d-flex">
              <span className="ml-auto mt-auto mb-auto" onClick={this.toggle}>
                <img src={close} alt="icon" width="40px" className="close-AM" />
              </span>
            </div>
            <div className="text-center" style={{ marginTop: "-50px" }}>
              <img src={Auser} alt="icon" width="50%" className="mb-3" />
              <h2 className="py-2 pb-3">Add New Users</h2>
              <Form
                role="form"
                className="mx-lg-5 mx-0 text-site-muted"
                onSubmit={(e) => {
                  e.preventDefault();
                  let obj = myUsers.find(
                    (user) => user.email == this.state.email
                  );
                  if (obj) {
                    alert("this email already registered!");
                  } else {
                    if (
                      this.state.email == "" &&
                      this.state.PhoneNumber == ""
                    ) {
                      alert("Please Add Email or PhoneNumber");
                    } else {
                      let temp = {
                        name: this.state.name,
                        company_id: uid,
                        jobTitle: this.state.jobTitle,
                        department: this.state.department,
                      };
                      if (this.state.email != "") {
                        temp["email"] = this.state.email;
                      }
                      if (this.state.PhoneNumber != "") {
                        temp["PhoneNumber"] = this.state.PhoneNumber;
                      }
                      console.log("Temp", temp);
                      this.props.addUsers(temp);
                      this.setState({
                        name: "",
                        PhoneNumber: "",
                        jobTitle: "",
                        department: "",
                      });
                      this.toggle();
                    }
                  }
                }}
              >
                <FormGroup className="mb-0">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Name"
                      onChange={this.handleInput}
                      name="name"
                      type="name"
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-0">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Job Title"
                      onChange={this.handleInput}
                      name="jobTitle"
                      type="text"
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0 mt-3"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-0">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Department"
                      onChange={this.handleInput}
                      name="department"
                      type="text"
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0 mt-3"
                    />
                  </div>
                </FormGroup>

                {/* <FormGroup className="mb-0">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={msg} alt="icon" width="16px" />
                    </div>
                    <Input
                      placeholder="Email"
                      onChange={this.handleInput}
                      name="email"
                      type="email"
                      // required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0 mt-3"
                    />
                  </div>
                </FormGroup> */}

                {/* <FormGroup className="mb-0">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={phone} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="Phone Number"
                      type="number"
                      onChange={this.handleInput}
                      name="PhoneNumber"
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0 mt-3"
                    />
                  </div>
                </FormGroup> */}

                <FormGroup className="mt-3">
                  <PhoneInput
                    placeholder="Phone Number"
                    value={this.state.PhoneNumber}
                    onChange={(e) => {
                      this.handleInput({
                        target: {
                          name: "PhoneNumber",
                          value: e,
                        },
                      });
                    }}
                    required
                  />
                </FormGroup>

                <div className="text-center">
                  <Button
                    // onClick={this.toggle}
                    className="my-3 py-3 w-100 bg-site-primary shadow"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>

        {/* ///// Delete User Modal  */}
        <Modal
          isOpen={this.state.modal1}
          toggle1={this.toggle1}
          style={{ maxWidth: "40%" }}
        >
          <ModalBody className="p-4 pb-5 mx-2">
            <div className="text-right d-flex">
              <span className="ml-auto mt-auto mb-auto" onClick={this.toggle1}>
                {/* <i className='fa fa-times close-AM'></i> */}
                <img src={close} alt="icon" width="40px" className="close-AM" />
              </span>
            </div>
            <div className="text-center mx-lg-5 mx-1">
              <img src={Auser} alt="icon" width="50%" />
              <h3>Are you sure you want to remove username?</h3>
              <div className="remove-user-btns">
                <Button color="site-muted" onClick={this.toggle1}>
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    this.props.deleteUsers(this.state.id, uid);
                    this.toggle1();
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.auth.uid,
    myUsers: state.user.myUsers,
    myCompanies: state.user.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUsers: (obj, companyId) => dispatch(addUsers(obj, companyId)),
    getUsers: (id) => dispatch(getUsers(id)),
    getCompany: (id) => dispatch(getCompany(id)),
    deleteUsers: (id, obj) => dispatch(deleteUsers(id, obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
