import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import hipaa from "../../assets/img/Landing page-slicing/HIPAA.png";
import iso from "../../assets/img/Landing page-slicing/ISO.png";
import pci from "../../assets/img/Landing page-slicing/PCI.png";
import soc from "../../assets/img/Landing page-slicing/SOC.png";
import gdpr from "../../assets/img/Landing page-slicing/GDPR.png";
import { useDispatch, useSelector } from "react-redux";
import { verifyCharity } from "../../Redux/Actions/charityAction";
import BlogSection from "../../components/landing/Blog";
import { useHistory } from "react-router-dom";
import People_Packing_cloth from "../../assets/img/lotties/people-packing-cloths-for-charity.json";
import woman_give_donation from "../../assets/img/lotties/woman-give-away-donation.json";
import medical_checkup from "../../assets/img/lotties/medical-check-up-consultations.json";
import man_give_food from "../../assets/img/lotties/young-man-giving-free-food-to-homeless.json";
import female_feeding_dog from "../../assets/img/lotties/young-female-feeding-dog.json";
import girl_help_poor from "../../assets/img/lotties/girl-helping-to-poor-man.json";

const Charity = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { verifyLoader } = useSelector((state) => state.charity);

  const charityData = [
    {
      img: female_feeding_dog,
    },
    {
      img: People_Packing_cloth,
    },
    {
      img: woman_give_donation,
    },
    {
      img: medical_checkup,
    },
    {
      img: man_give_food,
    },

    {
      img: girl_help_poor,
    },
  ];
  const data = [
    {
      img: hipaa,
    },
    {
      img: pci,
    },
    {
      img: soc,
    },
    {
      img: iso,
    },
    {
      img: gdpr,
    },
  ];
  const [charityModal, setCharityModal] = useState(false);
  const charitytoggle = () => {
    setCharityModal(!charityModal);
    setEinNumber("");
  };
  const [einNumber, setEinNumber] = useState("");
  return (
    <div className="charity text-center bg-white">
      {/* <div className="charity_bg"> */}
      {/* <div>
        <div className="">
          <h1 className="title pt-5">Free For Charities</h1>
          <div className="">
            <p class="secondary-text">
              Building for charity is building for a better world.
            </p>
            <p class="secondary-text">
              Work Chat is free for non-profit organizations. This is our little
              way of giving back.
            </p>
          </div>
          <Button
            color="site-primary"
            size="lg"
            className="mt-5 px-4 secondary-text"
            onClick={(e) => {
              e.preventDefault();
              charitytoggle();
            }}
          >
            Verify Charity
          </Button>
        </div>
        <Row className="my-5 py-3">
          {charityData?.map((obj) => (
            <>
              <Col className="py-2" md="4" lg="2">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: obj.img,
                  }}
                  height={150}
                  width={150}
                />
              </Col>
            </>
          ))}
        </Row>
      </div> */}
      <div id="blog" className="element">
        <BlogSection />
      </div>
      {/* <Container fluid="lg" className="pb-5"> */}
      {/* <h1 className="font-family-2 text-center font-weight-bold title">
        Communication That’s Compliant To The Core
      </h1>
      <Row className="text-left mx-lg-0 mx-sm-4 mx-2 justify-content-around px-md-5 px-0">
        {data?.map((item) => {
          return (
            <Col
              md="2"
              sm="6"
              className="mb-sm-0 mb-3 d-flex align-items-center flex-column"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80%"
                height="8px"
              >
                <path
                  fill-rule="evenodd"
                  stroke="#201cb1"
                  stroke-width="14.04px"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  fill="#201cb1"
                  d="M7.19,16.19 L7.19,7.19 L259.20,7.19 L259.20,16.19 L7.19,16.19 Z"
                />
              </svg>
              <div className="d-flex justify-content-center align-items-center">
                <img src={item?.img} className="w-100 h-auto" />
              </div>
            </Col>
          );
        })}
      </Row> */}
      {/* </Container> */}
      <Modal centered isOpen={charityModal} toggle={charitytoggle}>
        <ModalHeader toggle={charitytoggle}>Verify Charity</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              verifyCharity(einNumber, () => {
                setEinNumber("");
                history.push("/auth/register");
                charitytoggle();
              })
            );
          }}
        >
          <ModalBody>
            <Label>Enter EIN</Label>
            <Input
              type="text"
              placeholder="ein..."
              value={einNumber}
              onChange={(e) => {
                setEinNumber(e.target.value);
              }}
              required
            />
          </ModalBody>
          <ModalFooter>
            <Button color="site-primary" type="submit">
              {verifyLoader ? <Spinner size="sm" /> : "Verify"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default Charity;
