import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import G1 from "../../assets/img/Landing page-slicing/process-1.svg";
import G2 from "../../assets/img/Landing page-slicing/process-2.svg";
import G3 from "../../assets/img/Landing page-slicing/process-3.svg";
import ETE from "../../assets/img/Landing page-slicing/Encrypted.png";
import Encryption from "../../assets/img/lotties/Encryption.json";
import Lottie from "react-lottie";

class About extends Component {
  render() {
    return (
      <div className="bg-white">
        {/* <div className="bg-white about py-5  m-0 my-4">
          <Row className="align-items-center encrypted_paragraph">
            <Col xs="12" md="4" className="text-left pl-5 ml-5">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: Encryption,
                }}
                className="eteImg"
              />
            </Col>
            <Col xs="12" md="8" style={{ maxWidth: 'max-content' }} className='pr-5 p-0'>
              <h1 className="mt-0 ">
                End to End Encrypted <br /> Business Communication
              </h1>

              <h6 className="text-dark">
                End to end encryption is a method of secure communication that
                prevents third parties <br /> from accessing data while it is
                transferred from one device to another. The data is encrypted <br/> on
                the senders device and only the intended recipient can decrypt
                it.
              </h6>
            </Col>
          </Row>
        </div> */}
        <div className="bg-white about2 pt-5">
        <h1 className="font-family-2 text-center py-4" style={{ fontWeight: '700' }}>How It Works</h1>
          <Row md="3" sm="1" xs="1" className="img-div pt-4 pb-5 mb-0">
            <Col className="px-3">
              <img src={G1} />
            </Col>
            <Col className="px-3">
              <img src={G2} />
            </Col>
            <Col className="px-3">
              <img src={G3} />
            </Col>
          </Row>

          {/* <div className="hexagon"></div> */}
          {/* <div className="d-flex">
            <div className="left"></div>
            <div className="cnter"></div>
            <div className="right"></div>
          </div> */}
        </div>
      </div>
    );
  }
}
export default About;
