import firebase from "firebase/app";
// import firebase from 'firebase';
import "firebase/firestore";
import "firebase/auth";	
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyDYeWHFoePboBSC4NADW9pCIHRMQZkEAes",
	authDomain: "work-chat-67aa9.firebaseapp.com",
	projectId: "work-chat-67aa9",
	storageBucket: "work-chat-67aa9.appspot.com",
	messagingSenderId: "911636848713",
	appId: "1:911636848713:web:ee4d469edf25334b871f98",
	measurementId: "G-42DRZM7ZE8",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
db.settings({ timestampsInSnapshots: true });

export default firebase;
