import { RepositoryFactory } from "../../repository/RepositoryFactory";
var charity = RepositoryFactory.get("charity");
export const verifyCharity = (ein,onSuccess) => {
  return async (dispatch) => {
    dispatch(verifyLoader(true));
    try {
        const { data } = await charity.verifyCharity(ein);
        console.log(data.data,'data')
      if (data.data.public_charity) {
          localStorage.setItem(
            "verification_detail",
            JSON.stringify(data?.data)
        );
        dispatch(verifyLoader(false));
        onSuccess()
      } else {
        dispatch(verifyLoader(false));
        setTimeout(() => {
            alert("Try again EIN number is not valid!");
          }, 0);
      }
    } catch (error) {
      console.log(error.message);
      dispatch(verifyLoader(false));
    }
  };
};
const verifyLoader = (data) => async (dispatch) => {
  dispatch({
    type: "VERIFY_LOADER",
    payload: data,
  });
};
