import React, { useState } from "react";

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import visalogo from "../assets/img/dashboard-Slicing/Visa_Inc._logo.svg.png";
import selected from "../assets/img/dashboard-Slicing/selected.png";

import {
  CardNumberElement,
  CardExpiryElement,
  Elements,
  ElementsConsumer,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { TEST_CLIENT_KEY } from "./constants/index";
import { useDispatch, useSelector } from "react-redux";
import { addCustomer, removeCustomer } from "../Redux/Actions/userAction";

let stripePromise = loadStripe(TEST_CLIENT_KEY);

const StripeCard = () => {
  const [cardName, setCardName] = useState("");
  const { uid } = useSelector((state) => state.auth);
  const { customer, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  return (


    <>
      {customer != null && customer?.customerId && (
        <>
          <Card className="border-0 shadow w-100 my-2">
            <div className="d-flex my-1 ml-2">
              <img
                src={visalogo}
                alt="icon"
                width="7%"
                className="my-auto mx-3"
              />
              <div className="pl-2 my-auto">
                <div className="mb-n2">
                  <small className="mb-0 font-weight-bold">
                    {customer?.name}
                  </small>
                </div>
                <small className="text-site-muted">
                  {"XXXXXXXXXX" + customer?.last4}
                </small>
              </div>
              <div className="ml-auto my-auto mr-3">
                <img
                  src={selected}
                  alt="icon"
                //   width="7%"
                />
              </div>
            </div>
          </Card>
          <div className="d-flex p-0 justify-content-end mb-3">
            <span
              onClick={() => {
                dispatch(removeCustomer(customer?.customerId, uid));
                //   this.props.removeCustomer(this.props.customer.customerId, uid);
              }}
              className="text-site-muted mb-3 mt-2 cursor-pointer"
            >
              <strong>Remove</strong>
            </span>
          </div>
        </>
      )}

      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <Form
              className='p-3 payment-form'
              onSubmit={async (e) => {
                e.preventDefault();

                const cardElement = await elements.getElement(
                  CardNumberElement
                );
                const cardElement2 = await elements.getElement(
                  CardExpiryElement
                );
                const cardElement3 = await elements.getElement(
                  CardCvcElement
                );

                let { error, paymentMethod } =
                  await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    card: cardElement2,
                    card: cardElement3,
                  });
                if (error) {
                  alert(error?.message);
                } else {
                  const result = await stripe.createToken(cardElement);
                  if (result.error) {
                    alert(error.message);
                  } else {
                    dispatch(
                      addCustomer(
                        { cardName, token: result.token.id },
                        uid,
                        () => {
                          setCardName("");
                          // elements.getElement(cardElement).clear();
                          // elements.getElement(cardElement2).clear();
                          // elements.getElement(cardElement3).clear();

                          elements.getElement(CardNumberElement).clear();
                          elements.getElement(CardExpiryElement).clear();
                          elements.getElement(CardCvcElement).clear();
                        }
                      )
                    );
                  }
                }
              }}
            >
              <p>Card Name</p>
              <FormGroup>
                <div className="position-relative form-box">
                  <Input
                    placeholder="Card Name"
                    name="cardName"
                    type="name"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    required
                    className=" py-4"
                  />
                </div>
              </FormGroup>
              <p>Card Number</p>
              <FormGroup>
                <div className="form-control mt-2 d-flex align-items-center px-3 ">
                  <i className="fa fa-credit-card mr-2"></i>
                  <div
                    style={{
                      flexBasis: "95%",
                    }}
                  >
                    <CardNumberElement
                      required
                      options={{
                        placeholder: "4242 4242 4242 4242",
                        style: {
                          base: {
                            fontSize: "12px",
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
              <p>Expiry Date</p>
              <FormGroup>
                <div className="form-control mt-2 d-flex align-items-center px-3">
                  <i className="fa fa-calendar mr-2"></i>
                  <div
                    style={{
                      flexBasis: "95%",
                    }}
                  >
                    <CardExpiryElement
                      required
                      options={{
                        placeholder: "MM/YY",
                        style: {
                          base: {
                            fontSize: "12px",
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
              <p>CVC/CVV</p>
              <FormGroup>
                <div className="form-control mt-2 d-flex align-items-center px-3">
                  <div
                    style={{
                      flexBasis: "80%",
                    }}
                  >
                    <CardCvcElement
                      required
                      options={{
                        placeholder: "...",
                        style: {
                          base: {
                            fontSize: "12px",
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
              <Button
                className="w-100 bg-site-primary mt-3 mb-2"
                type="submit"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Add"}
              </Button>
            </Form>
          )}
        </ElementsConsumer>
      </Elements>
    </>
  );
};

export default StripeCard;
