import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from "reactstrap";
import { connect } from "react-redux";
import { forgetPassword, sendCode } from "../../Redux/Actions/authAction";
import { Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import email from "../../assets/img/dashboard-Slicing/Email.svg";
import password from "../../assets/img/dashboard-Slicing/Password.svg";
import logo from "../../assets/img/dashboard-Slicing/Logo_latest.png";
import Lottie from "react-lottie";
import loginLottie from "../../assets/img/lotties/auth.json";
import Navbar from "../../components/global/Navbar";
class ForgetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            code: "",
            password: "",
        };
    }
    sendCodehandler = () => {
        this.props.sendCode(this.state.email);
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    render() {
        const { uid, loading } = this.props;
        if (uid) return <Redirect to="/admin/index" />;
        return (
            <>
                <Row lg="2" sm="2" style={{ height: "calc(100vh - 80px)", marginTop: '5rem' }}>
                    <div className='position-absolute w-100' style={{ top: '0px', left: '0px' }}>
                        <Navbar />
                    </div>
                    <Col
                        lg="7"
                        sm="7"
                        className="d-flex justify-content-center align-items-center"
                    >
                        <div style={{ width: "70%" }}>
                            <div className="text-center  mb-4">
                                <img src={logo} alt="icon" height={"auto"} width={200} />
                                <h3 className="mt-2 mb-5 lgn-heading">Reset Your Password</h3>
                            </div>
                            <Form
                                role="form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    let creds;
                                    creds = {
                                        email: this.state.email,
                                        password: this.state.password,
                                    };
                                    console.log("login creds", creds);
                                    this.props.authLogin(creds);
                                }}
                            >
                                <FormGroup className="mb-3">
                                    <div className="position-relative form-box">
                                        <div className="position-absolute form-icon" left="10">
                                            <img src={email} alt="icon" width="16px" />
                                        </div>
                                        <Input
                                            onChange={this.handleInput}
                                            placeholder="Email"
                                            name="email"
                                            type="email"
                                            required
                                            autoComplete="new-email"
                                            className="pl-5 py-4 shadow-sm border-0"
                                        />
                                    </div>
                                </FormGroup>
                                <div className="text-center">
                                    <Button onClick={this.sendCodehandler} className="my-4 w-100 bg-site-primary shadow login-btn">
                                        {loading ? <Spinner size="sm" /> : "continue"}
                                    </Button>
                                </div>
                            </Form>

                            <div className="text-center">
                                remember your password?
                                <Link
                                    to={{ pathname: "/auth/login" }}
                                    className="text-site-primary ml-1"
                                >
                                    Login
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col
                        lg="5"
                        sm="5"
                        className="auth-bg d-flex justify-content-center align-items-center py-5"
                    >
                        <div>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: loginLottie,
                                }}
                                height={300}
                            />
                            <h1 className="text-white text-center mb-3">All of your employees. <br /> All in one place.</h1>
                            <h6 className="text-white text-center">
                                The #1 App For Business Communication
                            </h6>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export function mapStateToProps(state) {
    return {
        uid: state.auth.uid,
        loading: state.auth.requested,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendCode: (email) => dispatch(sendCode(email)),
        forgetPassword: (code, password) => dispatch(forgetPassword(code, password))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
