import firebase from "../../firebase";

export const addEventAction =
  (ID, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(eventLoader(true));
      const timestamp = firebase.firestore.Timestamp.fromDate(
        new Date(payload?.startTime)
      );
      const eventsDocRef = firebase.firestore().collection("events").doc(ID);
      const eventsDocSnapshot = await eventsDocRef.get();
      if (!eventsDocSnapshot.exists) {
        await eventsDocRef.set({});
      }
      const eventDataCollectionRef = eventsDocRef.collection("userEvents");
      await eventDataCollectionRef.add({
        ...payload,
        startTime: timestamp,
        addedBy: "admin",
      });
      dispatch(eventLoader(false));
      onSuccess();
    } catch (error) {
      dispatch(eventLoader(false));
      console.error("Error adding event:", error);
    }
  };

export const getEventsAction = (ID) => async (dispatch) => {
  dispatch(eventLoader(true));
  try {
    firebase
      .firestore()
      .collection("events")
      .doc(ID)
      .collection("userEvents")
      .onSnapshot((query) => {
        let events = [];
        for (let doc of query.docs) {
          if (doc.exists) {
            events.push({ id: doc.id, ...doc.data() });
          }
        }
        dispatch({
          type: "USER_EVENTS",
          payload: events,
        });
        dispatch(eventLoader(false));
      });
  } catch (error) {
    dispatch(eventLoader(false));
    console.error("Error Getting events:", error);
  }
};

export const eventLoader = (value) => async (dispatch) => {
  dispatch({
    type: "EVENT_LOADING",
    payload: value,
  });
};
