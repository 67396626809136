import React, { Component } from "react";
import { Container, Input } from "reactstrap";
import { Link } from "react-router-dom";
// Assets
import Logo from "../../assets/img/Landing page-slicing/Logo_latest.png";
import search from "../../assets/img/dashboard-Slicing/Search here.png";
import bell from "../../assets/img/dashboard-Slicing/notification.png";
import user from "../../assets/img/dashboard-Slicing/Users Name.png";

const Header = (props) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  // console.log("auth", auth);
  return (
    <>
      {/* <p className="mb-0">{auth && auth.user.name}</p> */}
      <Container fluid className="header w-100 border-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" className="menu-icon" onClick={props.onClick}>
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
        <Link className="link-logo" to="/">
          <img src={Logo} width={'auto'} height={80} alt="logo" />
        </Link>
        <div className="profile-avatar">
          <img src='https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg' alt="profile-image" />
        </div>
      </Container>
    </>
  );
}

export default Header;
