import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./UserReducer";
import CharityReducer from "./charityReducer";
import blogsReducer from "./blogReducer";
import historyReducer from "./historyReducer";
import scheduleReducer from "./scheduleReducer";
import announcementReducer from "./announcmentReducer";
import EventsReducers from "./EventsReducers";
import datanotesReducer from "./datanotesReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  charity: CharityReducer,
  blogs: blogsReducer,
  history: historyReducer,
  schedule: scheduleReducer,
  announcement: announcementReducer,
  events: EventsReducers,
  dataNotes: datanotesReducer,
});

export default rootReducer;
