const initState = {
  schedule: [],
  singleSchedule: [],
};

const scheduleReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_SCHEDULE_DATA":
      return {
        ...state,
        schedule: payload,
      };
    case "GET_SINGLE_SCHEDULE":
      return {
        ...state,
        singleSchedule: payload,
      };

    default:
      return state;
  }
};

export default scheduleReducer;
