import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getHistory } from "../../Redux/Actions/historyAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Spinner } from "reactstrap";
import close from "../../assets/img/dashboard-Slicing/close.svg";
import Auser from "../../assets/img/dashboard-Slicing/new-user.png";
import user from "../../assets/img/dashboard-Slicing/Users Name.png";
import {
  addSchedule,
  deleteSchedule,
  getSchedule,
  getSingleSchedule,
  updateSchedule,
} from "../../Redux/Actions/scheduleAction";

const Schedule = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { schedule, singleSchedule } = useSelector((state) => state.schedule);
  const { myUsers } = useSelector((state) => state.user);
  console.log(myUsers, "@###@");

  const filteredSchedule = schedule.filter((item) => item.userid === id);

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [formData, setFormData] = useState({
    userid: id,
    selectDate: "",
    selectClockIn: "",
    selectClockOut: "",
  });

  const [updateForm, setupdateForm] = useState({
    userid: id,
    selectDate: "",
    selectClockIn: "",
    selectClockOut: "",
  });

  const toggle = () => {
    setModal(!modal);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };
  const toggle3 = () => {
    setModal3(!modal3);
  };

  const handleDelete = (scheduleId) => {
    dispatch(deleteSchedule(scheduleId));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addSchedule(formData));
    console.log(formData);
    toggle();
    setFormData({
      userid: id,
      selectDate: "",
      selectClockIn: "",
      selectClockOut: "",
    });
  };

  const handleInputUpdate = (e) => {
    const { name, value } = e.target;
    setupdateForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    dispatch(updateSchedule(updateForm));
    console.log(updateForm);
    toggle2();
    setupdateForm({
      userid: id,
      selectDate: "",
      selectClockIn: "",
      selectClockOut: "",
    });
  };

  useEffect(() => {
    dispatch(getSchedule());
  }, []);

  useEffect(() => {
    if (singleSchedule) {
      setupdateForm(singleSchedule);
    }
  }, [singleSchedule]);

  return (
    <>
      <div className="d-flex align-items-center T-header p-3">
        <h2 className="text-site-muted">Schedule</h2>
        <Button color="site-primary" className="px-4" onClick={toggle}>
          Add New
        </Button>
      </div>
      <div className="mb-3 fs-1">
        {/* {myUsers &&
          myUsers.map((user) => {
            if (user.id === id) {
              return (
                <React.Fragment key={user.id}>
                  <span className="fs-1">
                    <b>Name:</b> {user.name}
                  </span>
                  <br />
                  <span>
                    <b>Phone:</b> {user.PhoneNumber}
                  </span>
                </React.Fragment>
              );
            }
            return null;
          })} */}
        <span className="fs-1">
          <b>User Id:</b> {id}
        </span>
      </div>

      <div className="T-contain">
        <table className="bg-white responsive">
          <thead>
            <tr>
              <th>Date</th>
              <th>ClockIn</th>
              <th>ClockOut</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredSchedule &&
              filteredSchedule?.map((us) => (
                <tr key={us?.id}>
                  <td>
                    <h5 className="join-date" style={{ whiteSpace: 'nowrap' }}>{us?.selectDate}</h5>
                  </td>
                  <td>
                    <h5 className="join-date">{us?.selectClockIn}</h5>
                  </td>
                  <td>
                    <h5 className="join-date">{us?.selectClockOut}</h5>
                  </td>
                  <td className="join-date">
                    <h5 className="d-flex m-0 p-0">
                    <Button
                      color="site-primary"
                      onClick={() => {
                        dispatch(getSingleSchedule(us.id));
                        toggle2();
                      }}
                    >
                      Edit Schedule
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => {
                        dispatch(getSingleSchedule(us.id));
                        toggle3();
                      }}
                    >
                      Remove
                    </Button>
                    </h5>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody className="p-4 mx-2">
            <div className="text-right d-flex">
              <span className="ml-auto mt-auto mb-auto" onClick={toggle}>
                <img src={close} alt="icon" width="40px" className="close-AM" />
              </span>
            </div>
            <div className="text-center" style={{ marginTop: "-50px" }}>
              <img src={Auser} alt="icon" width="50%" className="mb-3" />
              <h2 className="py-2 pb-3">Add New Schedule</h2>
              <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-2">
                  <label className="d-flex p-1">Select Date</label>
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Select Date"
                      type="date"
                      name="selectDate"
                      id="selectDate"
                      value={formData.selectDate}
                      onChange={handleInputChange}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-2">
                  <label className="d-flex p-1">Select Clock In</label>
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Select Clock In"
                      type="time"
                      name="selectClockIn"
                      id="selectClockIn"
                      value={formData.selectClockIn}
                      onChange={handleInputChange}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-2">
                  <label className="d-flex p-1">Select Clock Out</label>
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Select Clock Out"
                      type="time"
                      name="selectClockOut"
                      id="selectClockOut"
                      value={formData.selectClockOut}
                      onChange={handleInputChange}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-3 py-3 w-100 bg-site-primary shadow"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>
        {/* /// update modal  */}
        <Modal isOpen={modal2} toggle={toggle2}>
          <ModalBody className="p-4 mx-2">
            <div className="text-right d-flex">
              <span className="ml-auto mt-auto mb-auto" onClick={toggle2}>
                <img src={close} alt="icon" width="40px" className="close-AM" />
              </span>
            </div>
            <div className="text-center" style={{ marginTop: "-50px" }}>
              <img src={Auser} alt="icon" width="50%" className="mb-3" />
              <h2 className="py-2 pb-3">Add Update Schedule</h2>
              <Form onSubmit={handleSubmitUpdate}>
                <FormGroup className="mb-2">
                  <label className="d-flex p-1">Select Date</label>
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Select Date"
                      type="date"
                      name="selectDate"
                      id="selectDate"
                      value={updateForm.selectDate}
                      onChange={handleInputUpdate}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-2">
                  <label className="d-flex p-1">Select Clock In</label>
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Select Clock In"
                      type="time"
                      name="selectClockIn"
                      id="selectClockIn"
                      value={updateForm.selectClockIn}
                      onChange={handleInputUpdate}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-2">
                  <label className="d-flex p-1">Select Clock Out</label>
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={user} alt="icon" width="12px" />
                    </div>
                    <Input
                      placeholder="Select Clock Out"
                      type="time"
                      name="selectClockOut"
                      id="selectClockOut"
                      value={updateForm.selectClockOut}
                      onChange={handleInputUpdate}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-3 py-3 w-100 bg-site-primary shadow"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>

        {/* Delete modal  */}
        <Modal isOpen={modal3}  toggle={toggle3}>
        <ModalBody>
          <div className="p-3">
        <h4>Do you want to delete this Schedule?</h4>
        </div>
        </ModalBody>
        <ModalFooter>
          <Button color="site-primary" onClick={toggle3}>
            Cancel
          </Button>{' '}
          <Button color="danger" onClick={() => {
            handleDelete(singleSchedule?.id);
            toggle3();
          }}>
          Comfirm
          </Button>
        </ModalFooter>
      </Modal>
      </div>
    </>
  );
};

export default Schedule;
