const initState = {
    history: []
  };
  
  const historyReducer = (state = initState, { type, payload }) => {
    switch (type) {
      case "GET_HISTORY":
        return {
          ...state,
          history: payload,
        };
  
      default:
        return state;
    }
  };
  
  export default historyReducer;
  