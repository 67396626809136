import React from "react";

import { Card, CardBody, CardImg, CardText, Col, Row } from "reactstrap";

import Agriculture from "../../assets/img/lotties/Agriculture.json";
import Automotive from "../../assets/img/lotties/Automotive.json";
import Construction from "../../assets/img/lotties/Construction.json";
import Corporations from "../../assets/img/lotties/Corporations.json";
import Education from "../../assets/img/lotties/Education.json";
import Entertainment from "../../assets/img/lotties/Entertainment.json";
import Events from "../../assets/img/lotties/Events.json";
import Factories from "../../assets/img/lotties/Factories.json";
import Finance from "../../assets/img/lotties/Finance.json";
import Government from "../../assets/img/lotties/Government.json";
import Healthcare from "../../assets/img/lotties/Healthcare.json";
import Hospitality from "../../assets/img/lotties/Hospitality.json";
import Insurance from "../../assets/img/lotties/Insurance.json";
import LawFirms from "../../assets/img/lotties/Law Firms.json";
import Logistics from "../../assets/img/lotties/Logistics.json";
import Manufacturing from "../../assets/img/lotties/Manufacturing.json";
import Media from "../../assets/img/lotties/Media.json";
import Mortgage from "../../assets/img/lotties/Mortgage.json";
import NonProfits from "../../assets/img/lotties/Non Profits.json";
import PublicSector from "../../assets/img/lotties/Public Sector.json";
import RealEstate from "../../assets/img/lotties/Real Estate.json";
import Retail from "../../assets/img/lotties/Retail.json";
import sports from "../../assets/img/lotties/Sports.json";
import Technology from "../../assets/img/lotties/Technology.json";
import Lottie from "react-lottie";

const Industries = () => {
  const industries = [
    {
      img: Agriculture,
      title: "Agriculture",
    },
    {
      img: Automotive,
      title: "Automotive",
    },
    {
      img: Construction,
      title: "Construction",
    },
    {
      img: Corporations,
      title: "Corporations",
    },
    {
      img: Education,
      title: "Education",
    },
    {
      img: Entertainment,
      title: "Entertainment",
    },
    {
      img: Events,
      title: "Events",
    },
    {
      img: Factories,
      title: "Factories",
    },
    {
      img: Finance,
      title: "Finance",
    },
    {
      img: Government,
      title: "Government",
    },
    {
      img: Healthcare,
      title: "Healthcare",
    },
    {
      img: Hospitality,
      title: "Hospitality",
    },
    {
      img: Insurance,
      title: "Insurance",
    },
    {
      img: LawFirms,
      title: "Law Firms",
    },
    {
      img: Logistics,
      title: "Logistics",
    },
    {
      img: Manufacturing,
      title: "Manufacturing",
    },
    {
      img: Media,
      title: "Media",
    },
    {
      img: Mortgage,
      title: "Mortgage",
    },
    {
      img: NonProfits,
      title: "Non-Profits",
    },
    {
      img: PublicSector,
      title: "Public Sector",
    },
    {
      img: RealEstate,
      title: "Real Estate",
    },
    {
      img: Retail,
      title: "Retail",
    },
    {
      img: sports,
      title: "sports",
    },
    {
      img: Technology,
      title: "Technology",
    },
  ];

  return (
    <>
      <div className="industry">
        <div className="text-center cards2">
          <h1>Industries</h1>
          <Row lg="6" md="4" xs="3" className="row4">
            {industries.map((data, idx) => (
              <Col className="p-md-3 p-2" key={idx}>
                <Card className="shadow">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: data.img,
                    }}
                    height={100}
                    width={100}
                  />
                  <CardBody className="card13 pb-0">
                    <CardText className="mb-0 font-weight-bold card-textt text-uppercase mt-3">
                      {data.title}
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};
export default Industries;
