import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Input,
  ModalFooter,
  ModalHeader,
  Label,
} from "reactstrap";
import close from "../../assets/img/dashboard-Slicing/close.svg";
import description from "../../assets/img/dashboard-Slicing/description.svg";
import text from "../../assets/img/dashboard-Slicing/text.svg";
import Auser from "../../assets/img/dashboard-Slicing/new-user.png";
import user from "../../assets/img/dashboard-Slicing/Users Name.png";
import { Spinner } from "reactstrap";
import {
  addDataNote,
  deleteTodo,
  getNotes,
  updateDataNote,
  updateStatus,
} from "../../Redux/Actions/datanotesAction";
import { fetchUsers, getUsers } from "../../Redux/Actions/userAction";
import Select, { components } from "react-select";
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from "react-sortable-hoc";

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    const truncatedText = text.substr(0, maxLength);
    return `${truncatedText}...`;
  }
}

const arrayMove = (array, from, to) => {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
};

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle((props) => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

const DataNotes = () => {
  const dispatch = useDispatch();

  const { myUsers } = useSelector((state) => state.user);
  const { notes, isLoading } = useSelector((state) => state.dataNotes);
  const { uid } = useSelector((state) => state.auth);

  const todoUsers = myUsers.map((user) => ({
    value: user.PhoneNumber,
    label: user.name,
  }));
  console.log(todoUsers, "todoUsers");

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  console.log(selectedUsers, "selectedUsers");
  const [formData, setFormData] = useState({
    createdBy: "admin",
    title: "",
    desc: "",
    type: "todo",
    uid: selectedUsers,
  });

  const toggle = () => {
    setModal(!modal);
    setFormData({
      createdBy: "admin",
      title: "",
      desc: "",
      type: "todo",
      uid: [],
    });
  };
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);

  const handleDelete = (deleteID) => {
    dispatch(deleteTodo(deleteID));
    toggle3();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.id) {
      dispatch(updateDataNote({ ...formData, uid: selectedUsers }));
    } else {
      dispatch(addDataNote({ ...formData, uid: selectedUsers }));
    }

    toggle();
    setFormData({
      createdBy: "admin",
      title: "",
      desc: "",
      type: "todo",
      uid: [],
    });
  };

  const handleEdit = (data) => {
    setModal(!modal);
    setFormData(data);
  };

  const getNextStatus = (currentStatus) => {
    switch (currentStatus) {
      case "todo":
        return "inprogress";
      case "inprogress":
        return "done";
      case "done":
        return "todo";
      default:
        return "todo";
    }
  };

  const handleStatusUpdate = (id, nextStatus) => {
    console.log(id, nextStatus, "consoleDatabyahsan");
    dispatch(updateStatus(id, nextStatus));
  };

  useEffect(() => {
    dispatch(getNotes());
    dispatch(getUsers(uid));
  }, []);


  const onChange = (selectedOptions) => setSelectedUsers(selectedOptions);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedUsers((prevSelectedUsers) =>
      arrayMove(prevSelectedUsers, oldIndex, newIndex)
    );
  };

  const getTodoUserValues = () => {
    const todoUserValues = formData.uid
      ?.map((userPhoneNumber) => {
        const user = todoUsers.find((user) => user.value === userPhoneNumber);
        return user;
      })
      .filter((todoUser) => todoUser);

    return todoUserValues;
  };
  return (
    <>
      <div className="d-flex align-items-center T-header">
        <h2 className="text-site-muted">Todo</h2>
        <Button color="site-primary" className="px-4 py-2" onClick={toggle}>
          Add New
        </Button>
      </div>
      <div className="T-contain">
        {isLoading && (
          <div className="d-flex align-items-center justify-content-center spinner-container width-100%">
            <Spinner color="primary" />
          </div>
        )}
        {!isLoading && (
          <table className="bg-white responsive">
            <tr>
              <th>Date</th>
              <th>Title</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {notes &&
              notes.map((us, index) => (
                <tr key={index}>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <h5 className="join-date">
                      {moment
                        .unix(us?.updated?.seconds)
                        .format("MMMM DD, YYYY")}
                    </h5>
                  </td>
                  <td>
                    <h5 className="join-date">{us?.title}</h5>
                  </td>
                  <td>
                    <h5 className="join-date">
                      {truncateText(us?.desc[0]?.insert, 30)}
                    </h5>
                  </td>
                  <td>
                    <h5 className="join-date">
                      <Button
                        color={
                          us?.type === "todo"
                            ? "site-primary"
                            : us?.type === "inprogress"
                            ? "warning"
                            : "success"
                        }
                        onClick={() =>
                          handleStatusUpdate(us.id, getNextStatus(us.type))
                        }
                        disabled={us?.type == "done"}
                      >
                        {us?.type === "todo"
                          ? "Started"
                          : us?.type === "inprogress"
                          ? "In Progress"
                          : "Completed"}
                      </Button>
                    </h5>
                  </td>

                  <td>
                    <h5 className="d-flex m-0 p-0">
                      <Button
                        color="site-primary"
                        onClick={() => {
                          handleEdit(us);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        color="danger"
                        className="ml-2"
                        onClick={() => {
                          setDeleteID(us?.id);
                          toggle3();
                        }}
                      >
                        Remove
                      </Button>
                    </h5>
                  </td>
                </tr>
              ))}
          </table>
        )}

        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody className="p-4 mx-2 overflow-hidden ">
            <div className="text-right d-flex">
              <span className="ml-auto mt-auto mb-auto" onClick={toggle}>
                <img src={close} alt="icon" width="40px" className="close-AM" />
              </span>
            </div>
            <div className="text-center" style={{ marginTop: "-50px" }}>
              <img src={Auser} alt="icon" width="50%" className="mb-3" />
              <h2 className="py-2 pb-3">Add New Todo</h2>
              <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-2">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={text} alt="icon" width="17px" />
                    </div>
                    <Input
                      placeholder="Add Title"
                      type="text"
                      name="title"
                      id="selectDate"
                      value={formData.title}
                      onChange={handleInputChange}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="mb-2">
                  <div className="position-relative Ad-user-text">
                    <div className="position-absolute Ad-user-icon" left="10">
                      <img src={description} alt="icon" width="14px" />
                    </div>
                    <Input
                      placeholder="Add description"
                      type="text"
                      name="desc"
                      id="selectClockIn"
                      value={formData.desc[0]?.insert || formData.desc}
                      onChange={handleInputChange}
                      required
                      className="pl-5 py-4 bg-site-secondary form-control-M border-0"
                    />
                  </div>
                </FormGroup>

                <FormGroup className="mb-2 text-left">
                  <div className="position-relative Ad-user-text">
                  <Label className="font-weight-bolder" style={{fontSize: "15px"}}>Select Users</Label>

                    <SortableSelect
                      useDragHandle
                      axis="xy"
                      onSortEnd={onSortEnd}
                      distance={4}
                      getHelperDimensions={({ node }) =>
                        node.getBoundingClientRect()
                      }
                      isMulti
                      options={todoUsers}
                      defaultValue={getTodoUserValues(todoUsers)}
                      onChange={onChange}
                      components={{
                        MultiValue: SortableMultiValue,
                        MultiValueLabel: SortableMultiValueLabel,
                      }}
                      closeMenuOnSelect={false}
                    />
                  </div>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-3 py-3 w-100 bg-site-primary shadow"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal3} toggle={toggle3}>
          <ModalBody>
            <div className="p-3">
              <h4>Do you want to delete this Todo?</h4>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="site-primary" onClick={toggle3}>
              Cancel
            </Button>{" "}
            <Button
              color="danger"
              onClick={() => {
                handleDelete(deleteID);
              }}
            >
              Comfirm
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default DataNotes;
