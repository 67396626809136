const initState = {
  uid: "",
  veryfast: false,
  requested: false,
  user: null,
  loading: false,
  authError: "",
  registered: false,
  data: [],
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGNUP_REQUEST":
      return {
        ...state,
        requested: true,
      };
    case "SIGNUP_REQUEST_END":
      return {
        ...state,
        requested: false,
      };

    case "REGISTER_SUCCESS":
      return {
        ...state,
        registered: true,
        requested: false,
      };

    case "REGISTER_COMPLETE":
      return {
        ...state,
        registered: false,
      };
    case "ACTION_REQUEST":
      return {
        ...state,
        requested: true,
      };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        uid: action.user.id,
        user: { ...action.user },
        authError: "",
      };

    case "SET_REF":
      return {
        ...state,
        user: { ...state.user, ref: action.ref },
      };

    case "LOGIN_FAIL":
      return {
        ...state,
        uid: "",
        authError: action.error.message,
      };

    case "ACTION_REQUEST_END":
      return {
        ...state,

        requested: false,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        authError: "",
        uid: "",
        user: "",
      };
    case "UPDATE_DATA":
      return {
        ...state,
      };
    case "VERIFIED_ACCOUNT":
      return {
        ...state,
        veryfast: true,
      };
    case "LOGOUT_FAIL":
      return {};
    default:
      return state;
  }
};

export default authReducer;
