import React, { Component } from "react";
import { Button, Card, Form, Input, Label } from "reactstrap";
import paper from "../../assets/img/dashboard-Slicing/Paper1.svg";
import { connect } from 'react-redux';
import { getInvoices } from "../../Redux/Actions/userAction"

class Invoice extends Component {
  componentDidMount() {
    const { myUser, invoices } = this.props;
    if (myUser && myUser.customer_id) {
      if (invoices && invoices.length < 1) {
        console.log(myUser.customer_id);
        this.props.getInvoices(myUser.customer_id);
      }
    }
  }
  render() {
    const { invoices } = this.props;
    return (
      <div>
        <h3 className="text-site-muted my-3">Your Invoice</h3>
        <div className="invoice-sec">
          {invoices &&
            invoices.map((inv) => (
              <Card key={inv.id} className="shadow-sm border-0 invoice">
                <div className="invoice-avatar">
                  <img src={paper} alt="icon" />
                </div>
                <h5>{inv.customer_name}</h5>
                <div className="ml-auto my-auto">
                  <Button
                    onClick={() =>
                      (window.location.href = `${inv.invoice_pdf}`)
                    }
                    color="site-primary"
                  >
                    Download
                  </Button>
                </div>
              </Card>
            ))}

          {/* <Card className="shadow-sm border-0 invoice">
            <div className="invoice-avatar">
              <img src={paper} alt="icon" />
            </div>
            <h5>October 2021 Invoice</h5>
            <div className="ml-auto my-auto">
            <Button color="site-primary">Download</Button>
            </div>
          </Card>

          <Card className="shadow-sm border-0 invoice">
            <div className="invoice-avatar">
              <img src={paper} alt="icon" />
            </div>
            <h5>November 2021 Invoice</h5>
            <div className="ml-auto my-auto">
            <Button color="site-primary">Download</Button>
            </div>
          </Card> */}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    invoices: state.user.invoices,
    myUser: state.auth.user,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    getInvoices: (c_id) => dispatch(getInvoices(c_id)),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(Invoice);
