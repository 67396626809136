import React from 'react'
import {
    Button,
} from "reactstrap";

const ReferEarning = () => {
    return (
        <>
            <div className="d-flex align-items-center T-header">
                <h2 className="text-site-muted">TOTAL EARNING <strong>$400</strong></h2>
                <Button
                    color="site-primary"
                    className="px-4 py-2"
                >
                    Withdraw
                </Button>
            </div>
            <div className='T-contain'>
                <table className="bg-white">
                    <tr>
                        <th>Users</th>
                        <th>Joined Date</th>
                        <th>Email/Phone</th>
                        <th className='text-center'>Earn</th>
                    </tr>
                    <tr >
                        <td>
                            <div className="profile-detail">
                                <img src='https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg' alt="profile-image" height={48} width={48} />
                                <h5>Example</h5>
                            </div>
                        </td>
                        <td>
                            <h5 className="join-date">
                                12-5-2023
                            </h5>
                        </td>
                        <td>
                            <h5 className="join-date">
                                example@gmail.com
                            </h5>
                        </td>
                        <td className="action">
                            <p className='m-0'>$50</p>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}

export default ReferEarning