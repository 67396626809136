import firebase from "../../firebase";
let blogsCollection = firebase.firestore().collection("blogs");

export const setGetBlogsLoader = (val) => async (dispatch) => {
  dispatch({ type: "SET_GET_BLOGS_LOADER", payload: val });
};
export const setBlogsLoader = (val) => async (dispatch) => {
  dispatch({ type: "SET_BLOG_LOADER", payload: val });
};

export const fetchBlogs = () => async (dispatch) => {
  dispatch(setGetBlogsLoader(true));
  blogsCollection.onSnapshot(async (query) => {
    let tempData = [];
    for (let doc of query.docs) {
      tempData.push({ id: doc.id, ...doc.data() });
    }
    dispatch({ type: "FETCH_BLOGS", payload: tempData });
    dispatch(setGetBlogsLoader(false));
  });
};


export const fetchSingleBlog = (id) => async (dispatch) => {
  dispatch(setGetBlogsLoader(true));
  const userSnapshot = await blogsCollection.doc(id).get();
  let data = { id: userSnapshot.id, ...userSnapshot.data() };
  console.log("data at edit ", data);
   dispatch(setGetBlogsLoader(false));
  return data;
};




