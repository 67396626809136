import { Link } from "react-router-dom";
import { fetchBlogs } from "../../Redux/Actions/blogsAction";
import { useDispatch, useSelector } from "react-redux";
import LoadingComp from "../../components/global/LoadingComp";

import {
    Col,
    Container,
    Row,
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
} from "reactstrap";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import blogImage from '../../assets/img/Landing page-slicing/blog-image.png'

const BlogSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { blogs, isGettingBlogs } = useSelector((state) => state.blogs);

    useEffect(() => {
        dispatch(fetchBlogs());
    }, []);

    const formatedDate = (createdAt) => {
        const date = new Date(createdAt.seconds * 1000);
        const options = { year: "numeric", month: "long", day: "numeric" };
        const newDate = new Intl.DateTimeFormat("en-US", options).format(date);
        return newDate;
    };

    const homeBlogstitle = [
        'The Merk Network',
        'Introducing Merk',
        'Merk Construction Industry'
    ]

    return (
        <>
            <div className="position-relative bg-white">
                {isGettingBlogs ? (
                    <LoadingComp />
                ) : (
                    <div className="py-5 blogSection home-side-padding px-4">
                        <div>
                            <div className="pt-3">
                                <h1 className="font-family-2 text-center" style={{ fontWeight: '700' }}>Our Blog</h1>
                            </div>
                            <Row className="py-3" style={{ flexDirection: 'row-reverse' }}>
                                {blogs?.map((item) => {
                                    console.log(item?.title)
                                    return homeBlogstitle.includes(item?.title) && (
                                        <>
                                            {item.publish === true && (
                                                <Col
                                                    lg="4"
                                                    className="my-3"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(`/singleBlog/${item?.id}`);
                                                    }}
                                                >
                                                    <Link
                                                        className="h-100 d-block"
                                                        to={`/whats-on/blog/${item.name}`}
                                                    >
                                                        <Card className="d-flex blogSection__card flex-column h-100" style={{ background: '#f3f4ff' }}>
                                                            <img
                                                                className="img-fluid blogSection__inner__img"
                                                                src={blogImage}
                                                                alt="blog"
                                                            // for dynamic usage uncomment this line
                                                            // src={item?.featuredImgURL}
                                                            />

                                                            <div className="blogSection__inner p-3 bg-transparent">
                                                                <CardBody className="p-0">
                                                                    <CardTitle className="fs--22 blogSection__inner__title fw--700">
                                                                        <p className='text-center'>{item?.title}</p>
                                                                    </CardTitle>

                                                                    <div className="">
                                                                        <CardText
                                                                            className="fs-16 fw--400 border-0 blogSection__inner__text py-3 text-center"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    item?.description.slice(0, 120) +
                                                                                    "...",
                                                                            }}
                                                                        ></CardText>
                                                                    </div>
                                                                </CardBody>
                                                            </div>
                                                            <div className=" px-3 blogSection__button">
                                                                <CardBody>
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        {/* {item?.createdAt && (
                                          <CardText className="blogSection__card__date">
                                            {formatedDate(item?.createdAt)}
                                          </CardText>
                                        )} */}

                                                                        <button
                                                                            className="blogSection__inner__button text-center fs--16 fw--600"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                history.push(
                                                                                    `/singleBlog/${item?.id}`
                                                                                );
                                                                            }}
                                                                        >
                                                                            Read More
                                                                        </button>
                                                                    </div>
                                                                </CardBody>
                                                            </div>
                                                        </Card>
                                                    </Link>
                                                </Col>
                                            )}
                                        </>
                                    );
                                })}
                            </Row>
                            <div className="w-full d-flex align-items-center justify-content-center pt-4 ml-3">
                                <Button color="dark" size="lg" outline style={{ width: '150px' }} onClick={(e) => {
                                    e.preventDefault();
                                    history.push(
                                        '/blogsList'
                                    )
                                }}>See More</Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default BlogSection;