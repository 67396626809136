import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";

import store from "./Redux/Store";

// Import CSS here
import "./assets/css/style.css";
import "./assets/sass/custom.scss";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
