import firebase from "../../firebase";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

//....fetch data...

export const fetchUsers = (id) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("company")
      .doc(id)
      .onSnapshot((query) => {
        var tempSubscriptions = null;

        tempSubscriptions = { id: id, ...query.data() };
        console.log("tempSub", tempSubscriptions);
        dispatch({
          type: "GET_USERS",
          users: tempSubscriptions,
        });
      });
  };
};
//Send Messages
export const sendMesssage = (obj) => async (dispatch) => {
  firebase
    .firestore()
    .collection("messages")
    .add({
      ...obj,
      timestamps: firebase.firestore.Timestamp.fromDate(new Date()),
    })
    .then((response) => {
      alert("Message Successfully Send!");
    })
    .catch((err) => {
      alert(err.message);
    });
};
//Change logo
export const changeLogo = (obj, u_id) => async (dispatch) => {
  await dispatch({ type: "USER_ACTIONS_STARTS" });
  var company = await firebase
    .firestore()
    .collection("company")
    .doc(u_id)
    .get();
  var companyData = company.data();
  var companyImage = companyData.image;

  if (companyImage) {
    await firebase.storage().refFromURL(companyImage).delete();
    if (obj.img) {
      let filename = obj.img.name;
      let ext2 = filename.slice(filename.lastIndexOf("."));
      let file_name = uuidv4() + ext2.toLowerCase();
      let storageRef = await firebase
        .storage()
        .ref("company_img/" + file_name)
        .put(obj.img);
      let url = await storageRef.ref.getDownloadURL();
      if (url) {
        companyData.image = url;
        firebase
          .firestore()
          .collection("company")
          .doc(u_id)
          .update(companyData)
          .then((res) => {
            alert("Logo Updated Successfully");
            dispatch({ type: "USER_ACTIONS_END" });
          })
          .catch((err) => {
            alert(err.message);
            dispatch({ type: "USER_ACTIONS_END" });
          });
      }
    }
  } else {
    if (obj.img) {
      let filename = obj.img.name;
      let ext2 = filename.slice(filename.lastIndexOf("."));
      let file_name = uuidv4() + ext2.toLowerCase();
      let storageRef = await firebase
        .storage()
        .ref("company_img/" + file_name)
        .put(obj.img);
      let url = await storageRef.ref.getDownloadURL();
      if (url) {
        companyData.image = url;
        firebase
          .firestore()
          .collection("company")
          .doc(u_id)
          .update(companyData)
          .then((res) => {
            alert("Logo Updated Successfully");
          })
          .catch((err) => {
            alert(err.message);
          });
      }
    }
  }
};

// Add Users...

export const addUsers = (obj) => {
  console.log("users", obj);
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .add({
        ...obj,
        created_datetime: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then(async (res) => {
        console.log(obj, res.id);
        let employ = {
          added_date: new Date().toISOString().slice(0, 10),
          status: "added",
          user_id: res.id,
        };
        let totalEmployee = 0;
        firebase
          .firestore()
          .collection("unpaid_payments")
          .doc(obj.company_id)
          .get()
          .then((res) => {
            let response = res?.data();
            totalEmployee = response?.no_of_employees;
            firebase
              .firestore()
              .collection("unpaid_payments")
              .doc(obj.company_id)
              .update({
                employee_details:
                  firebase.firestore.FieldValue.arrayUnion(employ),
                no_of_employees: totalEmployee + 1,
              })
              .then((res) => console.log("added payment"))
              .catch((err) => console.log("error", err));
          });

        try {
          if (obj.email && obj.email != "") {
            let data = await axios.post(
              "https://us-central1-work-chat-67aa9.cloudfunctions.net/sendMail",
              { email: obj.email }
            );
          }

          if (obj.PhoneNumber && obj.PhoneNumber != "") {
            await axios
              .post(
                "https://us-central1-work-chat-67aa9.cloudfunctions.net/sendMessageOnPhoneNo",
                { phoneNo: obj.PhoneNumber }
              )
              .then((res) => {
                if (res.data.err) {
                  alert(res.data.err);
                }
              })
              .catch((err) => {
                alert(err);
              });
          }
        } catch (error) {}

        dispatch({
          type: "ADD_USER",
        });
        // dispatch(fetchUsers(obj.company_id));
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const addCustomer = (payload, id, onSuccess = () => {}) => {
  return async (dispatch) => {
    // console.log("idd", id, payload);
    dispatch({ type: "ADD_CUSTOMER_ACTION" });
    try {
      var dataAll = {};
      await firebase
        .firestore()
        .collection("company")
        .doc(id)
        .get()
        .then((res) => {
          dataAll = { id: id, ...res.data() };
        });

      console.log(
        "customer data",
        dataAll,
        dataAll.hasOwnProperty("customer_id")
      );
      if (dataAll.hasOwnProperty("customer_id")) {
        console.log("customer payload", payload);
        dispatch(removeCustomer(dataAll.customer_id, id));
        payload.customer_id = dataAll.customer_id;
        let { data } = await axios.post(
          "https://us-central1-work-chat-67aa9.cloudfunctions.net/createCard",
          payload
        );
        if (data.success) {
          // console.log('customer_id', data.customer.id);
          // let customerId = data.customer.id;
          // firebase
          //   .firestore()
          //   .collection('company')
          //   .doc(id)
          //   .update({ customer_id: customerId });
          dispatch({ type: "ADD_CUSTOMER_ACTION_END" });
          onSuccess();
        } else {
          dispatch({ type: "ADD_CUSTOMER_ACTION_END" });
          alert(data.error);
        }
      } else {
        let { data } = await axios.post(
          "https://us-central1-work-chat-67aa9.cloudfunctions.net/createCustomer",
          payload
        );
        if (data.success) {
          console.log("customer_id", data.customer.id);
          let customerId = data.customer.id;
          firebase
            .firestore()
            .collection("company")
            .doc(id)
            .update({ customer_id: customerId });
          dispatch({ type: "ADD_CUSTOMER_ACTION_END" });
          onSuccess();
        } else {
          dispatch({ type: "ADD_CUSTOMER_ACTION_END" });
          alert(data.error);
        }
      }
    } catch (error) {
      alert(error?.message || "Something went wrong");
      dispatch({ type: "ADD_CUSTOMER_ACTION_END" });
    }
  };
};
// fetch users on the basis of company_id...

export const getUsers = (id) => {
  console.log("get users action called", id);
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .where("company_id", "==", id)
      .onSnapshot((query) => {
        var tempSubscriptions = [];
        query.forEach((doc) => {
          tempSubscriptions.push({ id: doc.id, ...doc.data() });
          // console.log("MY temSub", tempSubscriptions);
        });
        dispatch({
          type: "GET_MY_USERS",
          myUsers: tempSubscriptions,
        });
      });
  };
};

// clear state on logout...
export const logoutComplete = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_STATE",
    });
  };
};

export const getCompany = () => {
  return async (dispatch) => {
    firebase
      .firestore()
      .collection("company")
      .onSnapshot((query) => {
        var tempSubscriptions = [];
        query.forEach((doc) => {
          tempSubscriptions.push({ id: doc.id, ...doc.data() });
          // console.log('MY company temSub', tempSubscriptions);
        });
        dispatch({
          type: "GET_COMPANY",
          company: tempSubscriptions,
        });
        let senderData = [];
        for (var i = 0; i < tempSubscriptions.length; i++) {
          if (tempSubscriptions[i].hasOwnProperty("customer_id")) {
            senderData.push(tempSubscriptions[i]);
          }
        }
        // axios
        //   .post(
        //     'http://localhost:5000/employer-panel-covid19/us-central1/getAllCompanies',
        //     { company: senderData }
        //   )
        //   .then((res) => {
        //     console.log(res.data);
        //   });
      });
  };
};

// remove users..

export const deleteUsers = (id, companyId) => {
  return (dispatch) => {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .delete()
      .then((res) => {
        firebase
          .firestore()
          .collection("unpaid_payments")
          .doc(companyId)
          .get()
          .then((res) => {
            let responseData = res.data();
            let arrayData = responseData.employee_details;
            let index;
            let startDate;
            for (var i = 0; i < arrayData.length; i++) {
              if (arrayData[i].user_id == id) {
                index = i;
                startDate = arrayData[i].added_date;
              }
            }
            let objectData = {
              added_date: startDate,
              status: "removed",
              removed_date: new Date().toISOString().slice(0, 10),
              user_id: id,
            };
            arrayData[index] = objectData;
            firebase
              .firestore()
              .collection("unpaid_payments")
              .doc(companyId)
              .update({
                employee_details: arrayData,
              })
              .then((res) => console.log("updated"))
              .catch((err) => console.log("error", err));
          });
      });
    dispatch({
      type: "DELETE_USERS",
    });
  };
};

export const fetchCards = (customerId) => {
  return async (dispatch) => {
    console.log("in fetch cards", customerId);
    let { data } = await axios.post(
      "https://us-central1-work-chat-67aa9.cloudfunctions.net/fetchCustomer",
      { customer_id: customerId }
    );
    if (data.success) {
      //   console.log('cust', data);
      if (data.paymentMethod.data.length > 0) {
        dispatch({
          type: "FETCH_CUSTOMER",
          payload: {
            customerId: data.customer.id,
            name: data.paymentMethod.data[0].name,
            last4: data.paymentMethod.data[0].last4,
            brand: data.paymentMethod.data[0].brand,
          },
        });
      } else {
        // dispatch({
        //   type: 'FETCH_CUSTOMER',
        //   payload: {
        //     customerId: data.customer.id,
        //     name: '',
        //     last4: '',
        //     brand: ''
        //   },
        // });
      }
    }
  };
};

export const removeCustomer = (customerId, id) => {
  return async (dispatch) => {
    // console.log('in fetch cards', customerId);
    let { data } = await axios.post(
      "https://us-central1-work-chat-67aa9.cloudfunctions.net/removeCard",
      { customer_id: customerId }
    );
    if (data.success) {
      // firebase.firestore().collection('company').doc(id).update({
      //   customer_id: firebase.firestore.FieldValue.delete(),
      // });
      dispatch({
        type: "DELETE_CUSTOMER",
      });
    }
  };
};

export const getInvoices = (c_id) => {
  return async (dispatch) => {
    console.log("item", c_id);
    let obj = {
      customer_id: c_id,
    };
    let { data } = await axios.post(
      "https://us-central1-work-chat-67aa9.cloudfunctions.net/getInvoices",
      obj
    );
    if (data.success) {
      dispatch({
        type: "GET_INVOICES",
        payload: data.data.data,
      });
    }
  };
};
