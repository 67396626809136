import Dashboard from "../views/admin/Dashboard";
import Invoice from "../views/admin/Invoice";
import ReferUser from "../views/admin/refer";
import ReferEarning from "../views/admin/earning";
import Landing from "../views/auth/Landing";
import Profile from "../views/admin/Profile";
import Smessage from "../views/admin/Smessage";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import Verify from "../views/auth/VerifyAccount";
import Contact from "../components/landing/Contact";
import Demo from "../views/auth/Demo";
import PrivacyPolicy from "../views/auth/PrivacyPolicy";
import TermsOfUse from "../views/auth/TermsOfUse";
import BlogList from "../views/auth/BlogSection";
import BlogSection from "../views/auth/BlogSection";
import SingleBlog from "../views/auth/SingleBlog";
import ForgetPassword from "../views/auth/ForgetPassword";
import History from "../views/admin/History";
import Events from "../views/admin/Events";
import Announcement from "../views/admin/Announcement";
import Schedule from "../views/admin/Schedule";
import DataNotes from "../views/admin/DataNotes";

let routes = [
  {
    path: "/auth/login",
    component: Login,
    layout: "auth",
  },
  {
    path: "/auth/forget-password",
    component: ForgetPassword,
    layout: "auth",
  },
  {
    path: "/auth/register",
    component: Register,
    layout: "auth",
  },
  {
    path: "/auth/verify/:id",
    component: Verify,
    layout: "auth",
  },
  {
    path: "/admin/index",
    component: Dashboard,
    layout: "admin",
  },
  {
    path: "/admin/history/:id",
    component: History,
    layout: "admin",
  },
  {
    path: "/admin/events/:id",
    component: Events,
    layout: "admin",
  },
  {
    path: "/admin/schedule/:id",
    component: Schedule,
    layout: "admin",
  },
  {
    path: "/admin/profile",
    component: Profile,
    layout: "admin",
  },
  {
    path: "/admin/announcements",
    component: Announcement,
    layout: "admin",
  },
  {
    path: "/admin/chat",
    component: Smessage,
    layout: "admin",
  },
  {
    path: "/admin/invoice",
    component: Invoice,
    layout: "admin",
  },
  {
    path: "/admin/datanotes",
    component: DataNotes,
    layout: "admin",
  },
  {
    path: "/admin/refer",
    component: ReferUser,
    layout: "admin",
  },
  {
    path: "/admin/refer/earning",
    component: ReferEarning,
    layout: "admin",
  },
  {
    path: "/",
    component: Landing,
    layout: "auth",
  },
  {
    path: "/demo",
    component: Demo,
    layout: "auth",
  },
  {
    path: "/blogsList",
    component: BlogSection,
    layout: "auth",
  },
  {
    path: "/singleBlog/:id",
    component: SingleBlog,
    layout: "auth",
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    layout: "auth",
  },

  {
    path: "/terms-of-use",
    component: TermsOfUse,
    layout: "auth",
  },

  {
    path: "/auth/contact",
    component: Contact,
    layout: "auth",
  },
];
export default routes;
