import React, { useEffect } from "react";
import Navbar from "../../components/global/Navbar";
import Footer from "../../components/global/Footer";
import { Container } from "reactstrap";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />
      <div className="bg-white py-5">
        <Container className="py-5 ">
          <strong>Merk Privacy Policy</strong>
          <p>
            <strong>Effective Date:</strong> June 23, 2023
          </p>
          <p>
            {" "}
            Merk ("we," "us," or "our") is committed to protecting the
            privacy and security of your personal information. This Privacy
            Policy explains how we collect, use, and disclose your personal
            information when you access or use the Merk website ("Website")
            and any services provided by Merk ("Service"). By accessing or
            using the Website and Service, you consent to the collection, use,
            and disclosure of your personal information as described in this
            Privacy Policy.
        </p>
          <strong>Information We Collect</strong>
          <p>
            (a) Personal Information: We may collect personal information that
            you provide directly to us when you register an account, use the
            Service, or communicate with us. This may include your name, email
            address, contact information, and any other information you choose
            to provide.
          </p>
          <p>
            (b) Usage Information: We may automatically collect certain
            information about your use of the Website and Service, such as your
            IP address, device information, browser type, operating system, and
            usage logs.
          </p>{" "}
          <p>
            (c) Cookies and Similar Technologies: We may use cookies and similar
            tracking technologies to collect information about your interactions
            with the Website and Service. You can manage your cookie preferences
            through your browser settings.
          </p>
          <strong>Use of Information</strong>
          <p>
            We may use the collected information for the following purposes:
          </p>{" "}
          <p>
            (a) To provide and improve the Service, including personalized
            features and content.
          </p>{" "}
          <p>
            (b) To communicate with you, respond to your inquiries, and provide
            customer support.
          </p>{" "}
          <p>
            (c) To send you updates, newsletters, and promotional materials.
          </p>{" "}
          <p>
            (d) To analyze and monitor usage trends, conduct research, and
            gather statistical information.
          </p>{" "}
          <p>
            (e) To detect, prevent, and address technical issues, fraud, or
            other unauthorized activities.
          </p>
          <strong>Disclosure of Information</strong>
          <p>
            We may disclose your personal information in the following
            circumstances:
          </p>{" "}
          <p>(a) With your consent or at your direction.</p>{" "}
          <p>
            (b) To third-party service providers, contractors, or partners who
            perform services on our behalf, such as hosting, data analysis,
            payment processing, customer support, or marketing.
          </p>{" "}
          <p>(c) As required by law, regulation, or legal process.</p>{" "}
          <p>
            (d) In connection with a merger, acquisition, or sale of all or a
            portion of our assets.
          </p>{" "}
          <p>
            (e) To protect our rights, property, or safety, or the rights,
            property, or safety of others.
          </p>
          <strong>Data Security</strong>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, alteration, or destruction.
            However, please note that no method of transmission over the
            internet or electronic storage is 100% secure.
          </p>
          <strong>Your Choices</strong>
          <p>
            (a) Account Information: You can review, update, or delete your
            account information by logging into your account or contacting us
            directly. Please note that we may retain certain information as
            required by law or for legitimate business purposes.
          </p>{" "}
          <p>
            (b) Marketing Communications: You can opt-out of receiving marketing
            communications from us by following the unsubscribe instructions in
            the communication or by contacting us.
          </p>
          <strong>Third-Party Links and Services</strong>
          <p>
            The Website and Service may contain links to third-party websites,
            services, or applications that are not owned or controlled by us.
            This Privacy Policy does not apply to third-party practices, and we
            are not responsible for the privacy practices or content of such
            third parties. We encourage you to review the privacy policies of
            those third parties before providing any personal information.
          </p>
          <strong>Children's Privacy </strong>
          <p>
            The Website and Service are not intended for individuals under the
            age of 18. We do not knowingly collect personal information from
            children. If you believe we have inadvertently collected personal
            information from a child, please contact us, and we will take steps
            to delete the information as soon as possible.
          </p>{" "}
          <strong>Changes to this Privacy Policy</strong>{" "}
          <p>
            We may update this Privacy Policy from time to time. The updated
            version will be indicated by an updated "Effective Date." We
            encourage you to review this Privacy Policy periodically for any
            changes. Your continued use of the Website and Service after the
            posting of any updates constitutes your acceptance of the updated
            Privacy Policy.
          </p>
          <strong>Contact Us</strong>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or our privacy practices, please contact us at{" "}
            <a href="support@merk.com">support@merk.com</a>
          </p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
