import React, { useEffect } from "react";
import Footer from "../../components/global/Footer";
import Contact from "../../components/landing/Contact";
import Navbar from "../../components/global/Navbar";

function Demo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Navbar />
      <Contact />
      <Footer />
    </>
  );
}

export default Demo;
