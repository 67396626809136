const initialState = {
  users: {},
  myUsers: [],
  companies: [],
  loading: false,
  customer: null,
  invoices: [],
  loading: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USERS':
      return {
        ...state,
        users: action.users,
      };
    case 'ADD_USER':
      return {
        ...state,
      };
    case 'USER_ACTIONS_STARTS':
      return {
        ...state,
        loading: true,
      };
    case 'USER_ACTIONS_END':
      return {
        ...state,
        loading: false,
      };
    case 'FETCH_CUSTOMER':
      return {
        ...state,
        customer: action.payload,
      };
    case 'DELETE_CUSTOMER':
      return {
        ...state,
        customer: null,
      };
    case 'GET_INVOICES':
      return {
        ...state,
        invoices: action.payload,
      };
    case 'ADD_CUSTOMER_ACTION':
      return {
        ...state,
        loading: true,
      };
    case 'ADD_CUSTOMER_ACTION_END':
      return {
        ...state,
        loading: false,
      };
    case 'GET_MY_USERS':
      return {
        ...state,
        myUsers: action.myUsers,
      };
    case 'GET_COMPANY':
      return {
        ...state,
        companies: action.company,
      };
    case 'DELETE_USERS':
      return {
        ...state,
      };
    case 'CLEAR_STATE':
      return {
        // ...state,
        customer: null,
        users: {},
        myUsers: [],
      };
    default:
      return state;
  }
};

export default userReducer;
