import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "reactstrap";
import Calendar from "react-calendar";
import AddEventModal from "../../components/EventComponents/AddEventModal";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom/cjs/react-router-dom";
import {
  addEventAction,
  getEventsAction,
} from "../../Redux/Actions/EventsActions";

function formatFirebaseTimestamp(timestamp) {
  const jsDate = timestamp.toDate();
  const month = jsDate.toLocaleString("en-US", { month: "long" });
  const day = jsDate.getDate();
  const year = jsDate.getFullYear();
  const hours = jsDate.getHours();
  const minutes = jsDate.getMinutes();
  const seconds = jsDate.getSeconds();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const formattedDate = `${month} ${day} ${year}`;
  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`;

  return { date: formattedDate, time: formattedTime };
}

const Events = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userEvents, eventsLoading } = useSelector((state) => state.events);
  console.log({ userEvents });
  const [date, setdata] = useState(new Date());
  const [eventsData, setEventsData] = useState([]);
  const [eventModal, setEventModal] = useState(false);

  useEffect(() => {
    if (userEvents?.length > 0) {
      const formattedEvents = userEvents.map((event) => {
        return {
          date: formatFirebaseTimestamp(event.startTime).date,
          time: formatFirebaseTimestamp(event.startTime).time,
          event: event.title,
        };
      });
      setEventsData(formattedEvents);
    }
  }, [userEvents]);
  console.log({ eventsData });

  const [singleEvent, setSingleEvent] = useState({
    name: "",
    time: "12:0 AM",
  });

  const handleChange = (value) => {
    setdata(value);
    console.log(value, "Value");
  };
  const addEvent = (e) => {
    e.preventDefault();
    let payload = {
      title: singleEvent.name,
      startTime:
        date
          ?.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })
          .replace(/,/g, "") +
        " " +
        singleEvent.time,
    };
    dispatch(
      addEventAction(id, payload, () => {
        setEventModal(false);
        setSingleEvent({
          name: "",
          time: "12:0 AM",
        });
      })
    );
  };

  useEffect(() => {
    dispatch(getEventsAction(id));
  }, [id]);
  const todayDateString = date
    .toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .replace(/,/g, "")
    .replace(/\b0/g, "") // Remove leading zero only if it's a standalone zero
    .trim();

  return (
    <>
      <div className="d-flex align-items-center T-header">
        <h2 className="text-site-muted">Events</h2>
      </div>
      <Card className="p-2 my-2">
        <div
          className="d-flex p-0 cursor-pointer"
          onClick={() => setEventModal(true)}
        >
          <svg
            className="mx-2"
            width="16px"
            height="16px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 12H18M12 6V18"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <strong className="mx-3">New Event</strong>
        </div>
      </Card>
      <Card>
        <h5 className="text-center my-3">
          Events on <strong>{date && date.toDateString()}</strong>
        </h5>
        <Row className="p-3">
          <Col
            lg={4}
            md={6}
            sm={6}
            className="d-md-flex justify-content-center"
          >
            <Calendar onChange={handleChange} value={date} />
          </Col>
          {eventsLoading ? (
            <Col
              lg={8}
              md={6}
              sm={6}
              className="d-flex justify-content-center p-0"
            >
              <Spinner size="sm" />
            </Col>
          ) : (
            <Col lg={8} md={6} sm={6} className="mt-3 mt-lg-0">
              {eventsData
                .filter((item) => item.date === todayDateString)
                .map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="events__single flex-column py-2 px-3 mb-3"
                    >
                      <p className="text-capitalize">
                        <strong> {item.event}</strong>
                      </p>
                      <div className="d-flex p-0 justify-content-between align-items-center">
                        <p>{item.date}</p>
                        <p>
                          <svg
                            fill="#000000"
                            width="25px"
                            height="25px"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M7.8 10a2.2 2.2 0 0 0 4.4 0 2.2 2.2 0 0 0-4.4 0z" />
                          </svg>
                          {item.time}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </Col>
          )}
        </Row>
      </Card>
      <AddEventModal
        eventModal={eventModal}
        setEventModal={setEventModal}
        singleEvent={singleEvent}
        setSingleEvent={setSingleEvent}
        addEvent={addEvent}
      />
    </>
  );
};

export default Events;
