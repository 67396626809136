import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import Clock from "./Clock";
import { useSelector } from "react-redux";

const AddEventModal = ({
  setEventModal,
  eventModal,
  singleEvent,
  setSingleEvent,
  addEvent,
}) => {
  const { eventsLoading } = useSelector((state) => state.events);
  return (
    <div>
      {" "}
      <Modal isOpen={eventModal} toggle={() => setEventModal(false)}>
        <ModalHeader toggle={() => setEventModal(false)}>Add Event</ModalHeader>
        <ModalBody className="py-1">
          <FormGroup>
            <Label for="exampleEmail">Event</Label>
            <Input
              placeholder="Event Title"
              type="text"
              value={singleEvent.name}
              onChange={(e) =>
                setSingleEvent({ ...singleEvent, name: e.target.value })
              }
            />
          </FormGroup>
          <Clock singleEvent={singleEvent} setSingleEvent={setSingleEvent} />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" toggle={() => setEventModal(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={addEvent}
            disabled={!singleEvent.name || !singleEvent.time || eventsLoading}
          >
            {eventsLoading ? <Spinner size="sm" /> : "Add Event"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddEventModal;
