import React, { useState } from "react";

import { Col, Row } from "reactstrap";
import Messages from "../../assets/img/Landing page-slicing/Messages.png";
import AudioCall from "../../assets/img/Landing page-slicing/5.png";
import VideoCall from "../../assets/img/Landing page-slicing/VideoCall.png";
import PushAudio from "../../assets/img/Landing page-slicing/4.png";
import PushVideo from "../../assets/img/Landing page-slicing/PushVideo.png";
import videot from "../../assets/img/Landing page-slicing/NEW Work Chat Explainer 1.mp4";
import videom from "../../assets/img/Landing page-slicing/NEW Work Chat Explainer2.mp4";
// import { info } from "../info";

const Features = () => {
  // let p1 = { ...info[0].features };
  // let [pl, setPl] = useState(p1);
  // let setfeatures = (pl) => {
  //   setPl(pl);
  // };
  return (
    <>
      <div className="features font-family-2">
        {/* <Row md="2" sm="1" className="bg-white">
          <Col md="6" sm="12" className=" m-auto d-flex flex-column">
            <h1 className="text-center">
              <strong>Features</strong>
            </h1>{" "}
            <video className="w-100 m-auto d-flex px-3" controls>
              <source src={videot} type="video/mp4" />
            </video>
          </Col>
          <Col md="6" sm="12" className=" m-auto d-flex flex-column">
            <h1 className="text-center">
              <strong>Benefits</strong>
            </h1>{" "}
            <video className="w-100 m-auto d-flex px-3" controls>
              <source src={videom} type="video/mp4" />
            </video>
          </Col>
        </Row> */}
        <h1 className="text-center mt-5 mb-0">
          <strong>Features</strong>
        </h1>
        {/* Bootstrap4 Tabs */}
        {/* <Row sm="1" xs="1" md="2" className="tabs__panel">
          <Col md={6} sm={12} className="justify-content-center">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <div id="audio-img" className="d-flex justify-content-center">
                  <img src={Messages} className="imgW" />
                </div>{" "}
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <div id="audio-img" className="d-flex justify-content-center">
                  <img src={AudioCall} className="imgW" />
                </div>{" "}
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <div id="message-img" className="d-flex justify-content-center">
                  <img src={VideoCall} className="imgW" />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="d-flex justify-content-center">
                  <img src={PushAudio} className="imgW" />
                </div>{" "}
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings2"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab2"
              >
                <div className="d-flex justify-content-center">
                  <img src={PushVideo} className="imgW" />
                </div>{" "}
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div
              className="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className="nav-link active m-sm-auto m-md-0 p-0"
                id="v-pills-home-tab"
                data-toggle="pill"
                href="#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                <div className="d-flex px-0">
                  <img src={msg} className="f-tabs-img" />
                  <h2 className="ml-4 my-auto">
                    <strong className="tab__heading">Message</strong>
                  </h2>
                </div>
              </a>
              <a
                className="nav-link p-0  m-sm-auto m-md-0"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                <div className="d-flex px-0">
                  <img src={Acall} className="f-tabs-img" />
                  <h2 className="ml-4 my-auto">
                    <strong className="tab__heading">Audio Call</strong>
                  </h2>
                </div>
              </a>
              <a
                className="nav-link p-0  m-sm-auto m-md-0"
                id="v-pills-messages-tab"
                data-toggle="pill"
                href="#v-pills-messages"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                <div className="d-flex px-0">
                  <img src={Vcall} className="f-tabs-img" />
                  <h2 className="ml-4 my-auto">
                    <strong className="tab__heading">Video Call</strong>
                  </h2>
                </div>
              </a>
              <a
                className="nav-link p-0  m-sm-auto m-md-0"
                id="v-pills-settings-tab"
                data-toggle="pill"
                href="#v-pills-settings"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                <div className="d-flex px-0">
                  <img src={Padi} className="f-tabs-img" />
                  <h2 className="ml-4 my-auto">
                    <strong className="tab__heading">Push Audio</strong>
                  </h2>
                </div>
              </a>
              <a
                className="nav-link p-0  m-sm-auto m-md-0"
                id="v-pills-settings-tab2"
                data-toggle="pill"
                href="#v-pills-settings2"
                role="tab"
                aria-controls="v-pills-settings2"
                aria-selected="false"
              >
                <div className="d-flex px-0">
                  <img src={Pvdi} className="f-tabs-img" />
                  <h2 className="ml-4 my-auto">
                    <strong className="tab__heading">Push Video</strong>
                  </h2>
                </div>
              </a>
            </div>
          </Col>
        </Row> */}

        <Row
          sm="2"
          xs="1"
          md="3"
          lg="5"
          className="py-5 px-xl-5 mx-xl-5 px-lg-3 mx-lg-4 px-md-3 mx-md-3 px-xl-5 mx-xl-5"
        >
          <Col>
            <div className="text-center py-4">
              <h2 className="m-auto">
                <strong className="tab-heading">Message</strong>
              </h2>
              <img src={Messages} className="imgF" />
            </div>
          </Col>
          <Col>
            <div className="text-center py-4">
              <h2 className="m-auto">
                <strong className="tab-heading">Audio Call</strong>
              </h2>
              <img src={AudioCall} className="imgF" />
            </div>
          </Col>
          <Col>
            <div className="text-center py-4">
              <h2 className="m-auto">
                <strong className="tab-heading">Video Call</strong>
              </h2>
              <img src={VideoCall} className="imgF" />
            </div>
          </Col>
          <Col>
            <div className="text-center py-4">
              <h2 className="m-auto">
                <strong className="tab-heading">Push Audio</strong>
              </h2>
              <img src={PushAudio} className="imgF" />
            </div>
          </Col>
          <Col>
            <div className="text-center py-4">
              <h2 className="m-auto">
                <strong className="tab-heading">Push Video</strong>
              </h2>
              <img src={PushVideo} className="imgF" />
            </div>
          </Col>
        </Row>

        {/* Bootstrap4 Tabs End */}

        {/* <Row sm="1" xs="1" md="2">
          <Col className="col_right">
            <div>
            <img
                // src={pl.img}
                src={Fimg}
                width="80%"
                className="ml-lg-5 ml-0 pl-lg-5 pl-0 mt-xl-n5 mt-lg-n1"
              />
            </div>
          </Col>
          <Col className="col_left">
            ?
            <div className="d-flex">
              <img src={msg} width="10%" />
              <h2 className="my-auto ml-4">
                <strong className="tab__heading">
                  <a href="#message-img" dataToggle="message-img">
                    Message
                  </a>
                </strong>
              </h2>
            </div>
            <div className="d-flex">
              <img src={Acall} width="10%" />
              <h2 className="my-auto ml-4">
                <strong>
                  <a href="#audio-img" dataToggle="audio-img">
                    Audio Call
                  </a>
                </strong>
              </h2>
            </div>
            <div className="d-flex">
              <img src={Vcall} width="10%" />
              <h2 className="my-auto ml-4">
                <strong>Video Call</strong>
              </h2>
            </div>
            <div className="d-flex">
              <img src={Padi} width="10%" />
              <h2 className="my-auto ml-4">
                <strong>Push Audio</strong>
              </h2>
            </div>
            <div className="d-flex">
              <img src={Pvdi} width="10%" />
              <h2 className="my-auto ml-4">
                <strong>Push Video</strong>
              </h2>
            </div>
          </Col>
        </Row>
        <Row sm="2" xs="2" md="4" className="m-0 row2">
          <Col className="p-0 col">
            <div id="message-img">
              <img src={fone1} width="110%" />
            </div>
          </Col>
          <Col className="p-0 col">
            <div id="audio-img">
              <img src={fone2} width="110%" />
            </div>{" "}
          </Col>
          <Col className="p-0 col">
            <div>
              <img src={fone3} width="110%" />
            </div>{" "}
          </Col>
          <Col className="p-0 col">
            <div>
              <img src={fone4} width="110%" />
            </div>{" "}
          </Col>
        </Row> */}
      </div>
    </>
  );
};
export default Features;
