import { useEffect, useState } from "react";
import { timePicker } from "analogue-time-picker";

export default function Clock({ singleEvent, setSingleEvent }) {
  const initialTime = singleEvent.time.split(":");
  const [showTime, setShowTime] = useState();
  useEffect(() => {
    setShowTime(
      timePicker({
        element: document.getElementById("clock"),
        mode: 12,
        width: "100%",
        time: { hour: initialTime[0], minute: 0 },
      })
    );
  }, []);
  const handleClick = () => {
    const object = showTime?.getTime();
    const formattedTime = object
      ? `${object.hour > 12 ? object.hour - 12 : object.hour}:${
          object.minute
        } ${object.hour >= 12 ? "PM" : "AM"}`
      : "";
    setSingleEvent({
      ...singleEvent,
      time: formattedTime,
    });
  };

  return (
    <div className="clock_div" onClick={handleClick} onKeyDown={handleClick}>
      <div id="clock"></div>
    </div>
  );
}
