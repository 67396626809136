import firebase from "../../firebase";

export const addSchedule = (data) => {
  console.log(data, ">>>>ahsaniqbal");
  return async (dispath) => {
    try {
      const addData = firebase.firestore().collection("schedule").add(data);
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };
};

export const getSchedule = () => async (dispatch) => {
  try {
    firebase
      .firestore()
      .collection("schedule")
      .orderBy("selectDate", "desc")
      .onSnapshot(async (data) => {
        let tempData = [];
        for (let doc of data.docs) {
          let data1 = doc.data();
          tempData.push({ id: doc.id, ...data1 });
        }
        console.log(tempData, "hamza>>>>>>");
        dispatch({ type: "GET_SCHEDULE_DATA", payload: tempData });
      });
  } catch (error) {
    alert(error.message);
  }
};

export const getSingleSchedule = (scheduleId) => async (dispatch) => {
  try {
    const docRef = firebase.firestore().collection("schedule").doc(scheduleId);

    docRef.onSnapshot(async (doc) => {
      if (doc.exists) {
        const singleSchedule = { id: doc.id, ...doc.data() };
        dispatch({ type: "GET_SINGLE_SCHEDULE", payload: singleSchedule });
      } else {
        console.log("Document does not exist");
        dispatch({
          type: "GET_SINGLE_SCHEDULE_ERROR",
          payload: "Document not found",
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateSchedule = (data) => async (dispatch) => {
  try {
    const { id, ...updatedData } = data;

    await firebase
      .firestore()
      .collection("schedule")
      .doc(id)
      .update(updatedData);

    console.log(data, "updatedatahamzaaaa>>>>>>>");
  } catch (error) {
    console.log(error);
  }
};

export const deleteSchedule = (scheduleId) => async (dispatch) => {
  try {
    await firebase.firestore().collection("schedule").doc(scheduleId).delete();

    dispatch({ type: "DELETE_SCHEDULE", payload: scheduleId });
  } catch (error) {
    alert(error.message);
  }
};

